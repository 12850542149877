<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">게시글 관리</h2>
        </div>
        <div class="contentBody">
            <h3 class="contentTitle">게시글 등록</h3>

            <!-- 입력 폼 -->
            <div class="infoWrap">
                <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
                <table class="infoTable marginBottom-35">
                    <tbody>
                        <tr>
                            <th class="requiredMark">게시판 선택</th>
                            <td>
                                <select
                                    class="selectBox size-300"
                                    v-model="articleInfo.contentsNo"
                                    @change.prevent="onContentsNoChange"
                                >
                                    <option
                                        v-for="board in boardList"
                                        :key="board.contentsNo"
                                        :value="board.contentsNo"
                                    >
                                        {{ board.contentsTitle }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">게시글 제목</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="articleInfo.articleTitle"
                                    v-validate="'required'"
                                    data-vv-name="articleTitle"
                                    data-vv-as="게시글 제목"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('articleTitle')"
                                >
                                    * {{ errors.first("articleTitle") }}
                                </p>
                            </td>
                        </tr>
                        <tr v-if="isShowNoticeUseYn">
                            <th class="requiredMark">공지여부</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-6"
                                            v-model="
                                                articleInfo.articleNoticeYn
                                            "
                                            value="Y"
                                        />
                                        <span>사용</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-6"
                                            v-model="
                                                articleInfo.articleNoticeYn
                                            "
                                            value="N"
                                        />
                                        <span>미사용</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">내용</th>
                            <td>
                                <p
                                    class="validation-text"
                                    v-if="errors.has('articleContents')"
                                >
                                    * {{ errors.first("articleContents") }}
                                </p>
                                <ckeditor
                                    v-validate="'required'"
                                    data-vv-name="articleContents"
                                    data-vv-as="내용"
                                    placeholder="내용을 입력해주세요."
                                    v-model="articleInfo.articleContents"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>첨부파일</th>
                            <td>
                                <common-file
                                    ref="FileUploader"
                                    v-model="articleInfo.fileList"
                                    style="display: none"
                                />
                                <ul class="fileList">
                                    <li
                                        class="fileListItem marginBottom-10"
                                        v-for="(
                                            file, index
                                        ) in articleInfo.fileList"
                                        :key="file.serverFileSaveName"
                                    >
                                        <a
                                            href="#"
                                            class="fileDownLink marginRight-10"
                                            download
                                            >{{ file.fileName }}</a
                                        >
                                        <span class="fileSize marginRight-10">{{
                                            file.fileSizeName
                                        }}</span>
                                        <button
                                            class="squareBtn size-50"
                                            @click="deleteFile(index)"
                                        >
                                            삭제
                                        </button>
                                    </li>
                                </ul>

                                <div class="inputBtnWrap">
                                    <button
                                        class="squareBtn bgLightGray"
                                        type="button"
                                        @click="openUploader('FileUploader')"
                                    >
                                        파일선택
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/article',
                            query: {
                                contentsNo: this.$route.query.contentsNo
                            }
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        목록
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgNavy"
                        @click="insertArticle()"
                    >
                        저장
                    </button>
                    <button class="radiusSmallBtn bgDark" @click="reset()">
                        취소
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import CommonFile from "@/components/common/CommonFile";
import ckeditor from "@/components/common/ckEditor.vue";
import EventBus from "../../script/EventBus";
export default {
    name: "ArticleCreate",
    data() {
        return {
            siteNo: "",
            boardList: [],
            item: {},
            editor: "",
            articleInfo: {
                contentsNo: "",
                articleTitle: "",
                articleContents: "",
                articleNoticeYn: "N",
                boardCommentUseYn: "N",
                fileGroupNo: "",
                fileList: [],
            },
            isShowNoticeUseYn : false
        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.selectBoardList();
        EventBus.$on("addFile", (fileList) => {
            this.logger.debug(this, "eventBus / addFile()");
            this.addFile(fileList);
        });
    },
    components: {
        CommonFile,
        ckeditor,
    },
    methods: {
        onContentsNoChange() {
            if ( this.articleInfo.contentsNo == 10 ) {
                this.$router.push({path :this.$route.path, query: {contentsNo : this.articleInfo.contentsNo}});
            }

            for (let board of this.boardList) {
                if ( board.contentsNo == this.articleInfo.contentsNo ) {
                    if ( board.boardNoticeUseYn == 'Y' ) {
                        // 공지여부 보임
                        this.isShowNoticeUseYn = true;
                    } else {
                        // 공지여부 숨김
                        this.isShowNoticeUseYn = false;
                    }
                }
                
            }
        },
        selectBoardList() {
            this.logger.debug(this, "selectBoardList()");
            var searchData = {};
            axios
                .post(
                    this.config.contextRoot + "/article/selectBoardList.do",
                    searchData
                )
                .then((response) => {
                    this.boardList = response.data.boardList;
                    if (this.boardList.length > 0) {
                        if (this.$route.query.contentsNo) {
                            this.articleInfo.contentsNo =
                                this.$route.query.contentsNo;
                        } else {
                            this.articleInfo.contentsNo =
                                this.boardList[0].contentsNo;
                        }
                        this.onContentsNoChange();
                    }
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        insertArticle() {
            this.logger.debug(this, "insertArticle()");

            if (!confirm("등록 하시겠습니까?")) {
                return;
            }
            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }

                    axios
                        .post(
                            this.config.contextRoot +
                                "/article/insertArticle.do",
                            this.articleInfo
                        )
                        .then((response) => {
                            if (response.data.articleNo) {
                                alert("등록 되었습니다.");
                                this.$router.replace(
                                    this.config.contextRoot +
                                        "/site/" +
                                        this.siteNo +
                                        "/article/" +
                                        response.data.articleNo +
                                        "/edit"
                                );
                            }
                        })
                        .catch((error) => {
                            this.exceptionHandler(this, error);
                        });
                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.error.all());
                    return false;
                });
        },

        reset() {
            if (confirm("취소 하시겠습니까?")) {
                this.$router.go(this.$router.currentRoute);
            }
        },
        openUploader(refName) {
            this.logger.debug(this, "openUploader()");
            this.$refs[refName].openUploader();
        },
        doDownload(data) {
            this.logger.debug(this, "doDownload");
            const formData = new FormData();
            formData.append("fileNo", data.fileNo);
            formData.append("fileName", data.fileName);
            formData.append("serverFileSaveName", data.serverFileSaveName);
            axios
                .post(
                    this.config.contextRoot + "/common/file/download.do",
                    formData,
                    { responseType: "blob" }
                )
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: response.headers["content-type"],
                        })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", data.fileName);
                    document.body.appendChild(link);
                    link.click();
                    location.reload();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        deleteFile(index) {
            this.articleInfo.fileList.splice(index, 1);
        },
        addFile(fileList) {
            this.articleInfo.fileList =
                this.articleInfo.fileList.concat(fileList);
        },
    },
};
</script>

<style>
</style>