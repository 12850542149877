import Vue from 'vue';
import VueRouter from 'vue-router';

import moment from 'moment';
/* 로그인 */
import Login from '@/components/login/Login';
/* 비밀번호 찾기 */
import FindPassword from '@/components/find/FindPassword';
/* 사이트정보*/
import SiteInfoLayout from '@/components/site/SiteInfoLayout';
/* 회원관리 */
import MemberLayout from '@/components/member/MemberLayout';
import MemberList from '@/components/member/MemberList';
import MemberCreate from '@/components/member/MemberCreate';
import MemberEdit from '@/components/member/MemberEdit';
/* 메뉴관리 */
import MenuLayout from '@/components/menu/MenuLayout';
import MenuList from '@/components/menu/MenuList';
import MenuCreate from '@/components/menu/MenuCreate';
import MenuEdit from '@/components/menu/MenuEdit';
/* 레이아웃 */
import LayoutLayout from '@/components/layout/LayoutLayout';
import LayoutList from '@/components/layout/LayoutList';
import LayoutCreate from '@/components/layout/LayoutCreate';
import LayoutEdit from '@/components/layout/LayoutEdit';
/* 게시판 */
import BoardLayout from '@/components/board/BoardLayout';
import BoardList from '@/components/board/BoardList';
import BoardCreate from '@/components/board/BoardCreate';
import BoardEdit from '@/components/board/BoardEdit';

/* 스킨관리 */
import SkinLayout from '@/components/skin/SkinLayout';
import SkinList from '@/components/skin/SkinList';
import SkinCreate from '@/components/skin/SkinCreate';
import SkinEdit from '@/components/skin/SkinEdit';
/* 게시글 관리 */
import ArticleLayout from '@/components/article/ArticleLayout';
import ArticleList from '@/components/article/ArticleList';
import ArticleCreate from '@/components/article/ArticleCreate';
import ArticleEdit from '@/components/article/ArticleEdit';
/* 컨텐츠관리 */
import ContentsLayout from '@/components/contents/ContentsLayout';
import ContentsList from '@/components/contents/ContentsList';
import ContentsCreate from '@/components/contents/ContentsCreate';
import ContentsEdit from '@/components/contents/ContentsEdit';
/* 회원사신청 */
import CompanyLayout from '@/components/companyApply/CompanyLayout';
import CompanyList from '@/components/companyApply/CompanyList';
import CompanyView from '@/components/companyApply/CompanyView';

/* 방문자 통계 */
import VisitorLayout from '@/components/visitor/VisitorLayout';

Vue.use(VueRouter);

//Config
Vue.prototype.config = {
    contextRoot: '/osolit-cms',
    proxyServerUrl: window.location.protocol +
        '//' +
        window.location.host +
        ':8080',
};

const routes = [{
        path: Vue.prototype.config.contextRoot + '/site/:siteNo',
        component: SiteInfoLayout,
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/siteInfo',
        component: SiteInfoLayout,
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/member',
        component: MemberLayout,
        children: [{
                path: '/',
                component: MemberList
            },
            {
                path: 'create',
                component: MemberCreate
            },
            {
                path: ':userNo/edit',
                component: MemberEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/menu',
        component: MenuLayout,
        children: [{
                path: '/',
                component: MenuList
            },
            {
                path: 'create',
                component: MenuCreate
            },
            {
                path: ':menuNo/edit',
                component: MenuEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/layout',
        component: LayoutLayout,
        children: [{
                path: '/',
                component: LayoutList
            },
            {
                path: 'create',
                component: LayoutCreate
            },
            {
                path: ':layoutNo/edit',
                component: LayoutEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/board',
        component: BoardLayout,
        children: [{
                path: '/',
                component: BoardList
            },
            {
                path: 'create',
                component: BoardCreate
            },
            {
                path: ':boardNo/edit',
                component: BoardEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/skin',
        component: SkinLayout,
        children: [{
                path: '/',
                component: SkinList
            },
            {
                path: 'create',
                component: SkinCreate
            },
            {
                path: ':skinNo/edit',
                component: SkinEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/article',
        component: ArticleLayout,
        children: [{
                path: '/',
                component: ArticleList
            },
            {
                path: 'create',
                component: ArticleCreate
            },
            {
                path: ':articleNo/edit',
                component: ArticleEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/contents',
        component: ContentsLayout,
        children: [{
                path: '/',
                component: ContentsList
            },
            {
                path: 'create',
                component: ContentsCreate
            },
            {
                path: ':contentsNo/edit',
                component: ContentsEdit
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/companyApply',
        component: CompanyLayout,
        children: [{
                path: '/',
                component: CompanyList
            },
            {
                path: ':companyInfoNo/view',
                component: CompanyView
            }
        ]
    },
    {
        path: Vue.prototype.config.contextRoot + '/site/:siteNo/visitorStats',
        component: VisitorLayout,
    },
    {
        path: Vue.prototype.config.contextRoot + '/findPassword',
        component: FindPassword
    },
    { path: Vue.prototype.config.contextRoot + '/login', component: Login },
    {
        path: Vue.prototype.config.contextRoot + '*',
        redirect: Vue.prototype.config.contextRoot + '/login',
    }
];

//UserInfo
Vue.prototype.userInfo = {
    loginId: sessionStorage.getItem('loginId'),
    userName: sessionStorage.getItem('userName'),
    roleName: sessionStorage.getItem('roleName'),
    isAdmin: function() {
        return sessionStorage.getItem('roleName') !== undefined &&
            sessionStorage.getItem('roleName').indexOf('ROLE_ADMIN') >= 0 ?
            true :
            false;
    },
};

//LogLevel
Vue.prototype.logLevel = 'debug'; //debug, warn, error, none

//Logger
Vue.prototype.logger = {
    debug: function(obj, args) {
        if (Vue.prototype.logLevel == 'debug') {
            var exportName = '';
            if (obj instanceof String) {
                exportName = obj;
            } else {
                exportName = obj.$options.name;
            }
            console.log('[' + exportName + ']' + args);
        }
    },
    warn: function(obj, args) {
        if (Vue.prototype.logLevel == 'debug' || Vue.prototype.logLevel == 'warn') {
            var exportName = '';
            if (obj instanceof String) {
                exportName = obj;
            } else {
                exportName = obj.$options.name;
            }
            console.log('[' + exportName + ']' + args);
        }
    },
    error: function(obj, args) {
        if (
            Vue.prototype.logLevel == 'debug' ||
            Vue.prototype.logLevel == 'warn' ||
            Vue.prototype.logLevel == 'error'
        ) {
            var exportName = '';
            if (obj instanceof String) {
                exportName = obj;
            } else {
                exportName = obj.$options.name;
            }
            console.log('[' + exportName + ']' + args);
        }
    },
};

//Utils
Vue.prototype.utils = {
    isNull: function(args) {
        if (args === undefined || args == null || args == '') {
            return true;
        } else {
            return false;
        }
    },
    isEmpty: function(args) {
        return this.isNull(args);
    },
    formatDate(value) {
        return moment(value).format('YYYY-MM-DD');
    },
};

//AXIOS ExceptionHandler
Vue.prototype.exceptionHandler = function(obj, errors) {
    Vue.prototype.logger.debug(obj, errors);
    console.log(errors.response);

    if (!Vue.prototype.utils.isEmpty(errors.response.status)) {
        let statusCode = errors.response.status;
        let errorData = Vue.prototype.utils.isEmpty(errors.response.data.error) ?
            errors.response.status :
            errors.response.data.error;
        let message = Vue.prototype.utils.isEmpty(errors.response.data.message) ?
            errors.response.statusText :
            errors.response.data.message;

        if (statusCode == 403) {
            alert('[' + errorData + ']' + message);

            sessionStorage.setItem('loginId', '');
            sessionStorage.setItem('userName', '');
            sessionStorage.setItem('roleName', '');
            Vue.prototype.userInfo = {};
            this.$router.push(Vue.prototype.config.contextRoot);
        } else {
            alert('[' + errorData + ']' + message);

            //this.$router.push(Vue.prototype.config.contextRoot);
        }
    }
};

console.log(Vue.prototype.config.proxyServerUrl);
console.log(Vue.prototype.config.contextRoot);

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    console.log('[router.js]path:' + from.path + ' --> ' + to.path);
    console.log('this.userInfo.loginId:' + Vue.prototype.userInfo.loginId);

    if (to.path.indexOf('/findPassword') > 0) {
        next();
        return;
    }

    if (
        (to.path.indexOf('/login') == -1) &&
        (Vue.prototype.utils.isNull(sessionStorage.getItem('roleName')) ||
            sessionStorage.getItem('roleName') == 'ROLE_ANONYMOUS')

    ) {
        Vue.prototype.userInfo = {};
        sessionStorage.setItem('roleName', '');
        sessionStorage.setItem('loginId', '');
        sessionStorage.setItem('userName', '');
        next(Vue.prototype.config.contextRoot + '/login');
    } else {
        next();
    }
});

export default router;