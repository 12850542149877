<template>
<div class="wrapper">
    <Gnb/>
    <Top/>

    <ArticleCompanyCreate v-if="this.$route.fullPath.indexOf('create?contentsNo=10') > -1"></ArticleCompanyCreate>
    <ArticleCompanyEdit v-else-if="this.$route.fullPath.indexOf('edit?contentsNo=10') > -1"></ArticleCompanyEdit>
    <router-view v-else></router-view>
</div>
</template>

<script>
import Gnb from '@/components/gnb/Gnb.vue';
import Top from '@/components/top/Top.vue';
import ArticleCompanyCreate from '@/components/article/ArticleCompanyCreate.vue';
import ArticleCompanyEdit from '@/components/article/ArticleCompanyEdit.vue';
export default {
    name : 'ArticleLayout',
    components : {
        Gnb,
        Top,
        ArticleCompanyCreate,
        ArticleCompanyEdit
    },
    created() {
        
    }
}
</script>

<style>

</style>