<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">스킨</h2>
        </div>
        <div class="contentBody">
            <h3 class="contentTitle">스킨 등록</h3>

            <!-- 입력 폼 -->
            <div class="infoWrap">
                <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
                <table class="infoTable tableFixed marginBottom-35">
                    <tbody>
                        <tr>
                            <th class="requiredMark">스킨 이름</th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="skinInfo.skinName"
                                    v-validate="'required'"
                                    data-vv-name="skinName"
                                    data-vv-as="스킨 이름"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('skinName')"
                                >
                                    * {{ errors.first("skinName") }}
                                </p>
                            </td>
                            <th class="requiredMark">사용여부</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-6"
                                            v-model="skinInfo.useYn"
                                            value="Y"
                                        />
                                        <span>사용</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-6"
                                            v-model="skinInfo.useYn"
                                            value="N"
                                        />
                                        <span>미사용</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="sectionLabel">
                    <strong class="sectionLabelTitle">조회스킨</strong>
                </div>
                <p
                    class="validation-text"
                    v-if="errors.has('skinContentsView')"
                >
                    * {{ errors.first("skinContentsView") }}
                </p>
                <textarea
                    class="textArea borderDarkGray marginBottom-15"
                    v-validate="''"
                    data-vv-name="skinContentsView"
                    data-vv-as="조회스킨"
                    v-model="skinInfo.skinContentsView"
                    placeholder="내용을 입력하세요."
                ></textarea>
                <div class="sectionLabel">
                    <strong class="sectionLabelTitle">목록스킨</strong>
                </div>
                <p
                    class="validation-text"
                    v-if="errors.has('skinContentsList')"
                >
                    * {{ errors.first("skinContentsList") }}
                </p>
                <textarea
                    class="textArea borderDarkGray marginBottom-15"
                    v-validate="''"
                    data-vv-name="skinContentsList"
                    data-vv-as="목록스킨"
                    v-model="skinInfo.skinContentsList"
                    placeholder="내용을 입력하세요."
                ></textarea>
                <div class="sectionLabel">
                    <strong class="sectionLabelTitle">등록스킨</strong>
                </div>
                <p
                    class="validation-text"
                    v-if="errors.has('skinContentsCreate')"
                >
                    * {{ errors.first("skinContentsCreate") }}
                </p>
                <textarea
                    class="textArea borderDarkGray marginBottom-15"
                    v-validate="''"
                    data-vv-name="skinContentsCreate"
                    data-vv-as="등록스킨"
                    v-model="skinInfo.skinContentsCreate"
                    placeholder="내용을 입력하세요."
                ></textarea>
                <div class="sectionLabel">
                    <strong class="sectionLabelTitle">수정스킨</strong>
                </div>
                <p
                    class="validation-text"
                    v-if="errors.has('skinContentsUpdate')"
                >
                    * {{ errors.first("skinContentsUpdate") }}
                </p>
                <textarea
                    class="textArea borderDarkGray"
                    v-validate="''"
                    data-vv-name="skinContentsUpdate"
                    data-vv-as="수정스킨"
                    v-model="skinInfo.skinContentsUpdate"
                    placeholder="내용을 입력하세요."
                ></textarea>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/skin',
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        목록
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button class="radiusSmallBtn bgNavy" @click="insertSkin()">
                        저장
                    </button>
                    <button class="radiusSmallBtn bgDark" @click="reset()">
                        취소
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "SkinCreate",
    data() {
        return {
            siteNo: "",
            skinInfo: {
                skinName: "",
                useYn: "Y",
                skinContentsView: "",
                skinContentsList: "",
                skinContentsCreate: "",
                skinContentsUpdate: "",
            },
        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
    },
    methods: {
        insertSkin() {
            this.logger.debug(this, "insertSkin()");

            if (!confirm("등록 하시겠습니까?")) {
                return;
            }

            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }

                    this.skinInfo.siteNo = this.siteNo;

                    axios
                        .post(
                            this.config.contextRoot + "/skin/insertSkin.do",
                            this.skinInfo
                        )
                        .then((response) => {
                            if (response.data.skinNo) {
                                alert("등록 되었습니다.");
                                this.$router.replace(
                                    this.config.contextRoot +
                                        "/site/" +
                                        this.siteNo +
                                        "/skin/" +
                                        response.data.skinNo +
                                        "/edit"
                                );
                            }
                        })
                        .catch((error) => {
                            this.exceptionHandler(this, error);
                        });
                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.errors.all());
                    return false;
                });
        },

        reset() {
            if (confirm("취소 하시겠습니까?")) {
                this.$router.go(this.$router.currentRoute);
            }
        },
    },
};
</script>

<style>
</style>