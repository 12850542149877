import Vue from "vue"
import axios from "axios"

export default "Auth"

const methods = {
    login: function(loginId, loginPassword, result) {
        console.log("[Auth.js]login(" + loginId + "," + loginPassword + ")");

        const data = {
            loginId: loginId,
            loginPassword: loginPassword
        };

        axios.post(Vue.prototype.config.contextRoot + "/user/login.do", data)
            .then(function(response) {

                console.log("loginInfo ====> ", response);

                sessionStorage.setItem("loginId", response.data.message.loginId);
                sessionStorage.setItem("userName", response.data.message.userName);
                sessionStorage.setItem("roleName", response.data.message.roleName);
                sessionStorage.setItem("userIp", response.data.message.userIp);

                Vue.prototype.userInfo = {
                    loginId: sessionStorage.getItem("loginId"),
                    userName: sessionStorage.getItem("userName"),
                    roleName: sessionStorage.getItem("roleName"),
                    //router.js에도 같은 UserInfo 객체가 있어서 수정 해야함
                    isAdmin: function() {
                        return !Vue.prototype.utils.isNull(sessionStorage.getItem("roleName")) && sessionStorage.getItem("roleName").indexOf("ROLE_ADMIN") >= 0 ? true : false;
                    },
                    isUser: function() {
                        console.log(sessionStorage.getItem("roleName"));
                        return !Vue.prototype.utils.isNull(sessionStorage.getItem("roleName")) && sessionStorage.getItem("roleName").indexOf("ROLE_USER") >= 0 ? true : false;
                    },
                    isAnonymous: function() {
                        //console.log(sessionStorage.getItem("roleName"));
                        return Vue.prototype.utils.isNull(sessionStorage.getItem("roleName")) || (sessionStorage.getItem("roleName") !== undefined && (sessionStorage.getItem("roleName") == "" || sessionStorage.getItem("roleName") == "ROLE_ANONYMOUS")) ? true : false;
                    },

                }

                result(true);
            })
            .catch(function(error) {

                let errorTitle = error.response.statusText;
                let message = error.response.data.message;

                if (message == "Bad credentials") {
                    message = "인증에 실패했습니다.";
                }

                alert("[" + errorTitle + "]" + message);
            });
    },
    logout: function(result) {
        console.log("[Auth.js]logout()");

        axios.post(Vue.prototype.config.contextRoot + "/user/logout.do")
            .then(function(response) {
                console.log(response);

                sessionStorage.setItem("loginId", "");
                sessionStorage.setItem("userName", "");
                sessionStorage.setItem("roleName", "");
                sessionStorage.setItem("userIp", "");
                Vue.prototype.userInfo = {};
                result(true);
            })
            .catch(function(error) {
                let errorTitle = error.response.statusText;
                let message = error.response.data.message;

                alert("[" + errorTitle + "]" + message);
                result(false);
            });

        result(true);
    },
    info: function() {
        axios
            .post(Vue.prototype.config.contextRoot + "/user/info.do")
            .then(function(response) {
                console.log(response);

                if (response.data.loginId != sessionStorage.getItem("loginId") || response.data.roleName != sessionStorage.getItem("roleName")) {
                    sessionStorage.setItem("loginId", response.data.loginId);
                    sessionStorage.setItem("userName", response.data.userName);
                    sessionStorage.setItem("roleName", response.data.roleName);
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(function(error) {
                //console.log(error.response);
                sessionStorage.setItem("loginId", "");
                sessionStorage.setItem("userName", "");
                sessionStorage.setItem("roleName", "");
            });
    }
};

Vue.prototype.$Auth = methods;