<template>
<div class="contentLayout">
    <div class="contentNavigationBar">
        <h2 class="title">레이아웃</h2>
    </div>
    <div class="contentBody">
        <h3 class="contentTitle">레이아웃 등록</h3>
        
        <!-- 입력 폼 -->
        <div class="infoWrap">
            <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
            <table class="infoTable marginBottom-35">
                <tbody>
                    <tr>
                        <th class="requiredMark">레이아웃명</th>
                        <td colspan="3">
                             <input 
                                    type="text" 
                                    class="inputBox" 
                                    v-model="layoutInfo.layoutName" 
                                    v-validate="'required'"
                                    data-vv-name="layoutName"
                                    data-vv-as="레이아웃명"
                                >
                                <p class="validation-text" v-if="errors.has('layoutName')">
                                    * {{ errors.first("layoutName") }}
                                </p>
                        </td>
                    </tr>
                    <tr>
                        <th class="requiredMark">사용여부</th>
                        <td colspan="3">
                            <div class="radioBoxWrap">
                                <label>
                                   <input 
                                    type="radio" 
                                    class="radioBox" 
                                    name="testRadio-6" 
                                    v-model="layoutInfo.useYn"
                                    value="Y"
                                >
                                    <span>사용</span>
                                </label>
                                <label>
                                   <input 
                                    type="radio" 
                                    class="radioBox" 
                                    name="testRadio-6" 
                                    v-model="layoutInfo.useYn"
                                    value="N"
                                >
                                    <span>미사용</span>
                                </label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="sectionLabel">
                <strong class="sectionLabelTitle">헤더</strong>
            </div>
            <p class="validation-text" v-if="errors.has('layoutHeader')">
                                    * {{ errors.first("layoutHeader") }}
                                </p>
            <textarea class="textArea borderDarkGray marginBottom-15" v-validate="'required'" data-vv-name="layoutHeader"
                                    data-vv-as="헤더" v-model="layoutInfo.layoutHeader" placeholder="내용을 입력하세요."></textarea>
                                    
            <div class="sectionLabel">
                <strong class="sectionLabelTitle">푸터</strong>
            </div>
            <p class="validation-text" v-if="errors.has('layoutFooter')">
                                    * {{ errors.first("layoutFooter") }}
                                </p>
            <textarea class="textArea borderDarkGray" v-validate="'required'"   data-vv-name="layoutFooter"
                                    data-vv-as="푸터"  v-model="layoutInfo.layoutFooter" placeholder="내용을 입력하세요."></textarea>
                                    
        </div>

        <!-- 하단 버튼 바 -->
        <div class="contentButtonBar">
            <div class="buttonWrap floatLeft">
                <router-link :to="{path: config.contextRoot + '/site/' + $route.params.siteNo + '/layout'}" class="radiusSmallBtn bgGray">
                목록
                </router-link>
            </div>
            <div class="buttonWrap floatRight">
                <button class="radiusSmallBtn bgNavy" @click="insertLayout()">저장</button>
                <button class="radiusSmallBtn bgDark" @click="reset()">취소</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
export default {
    name : 'LayoutCreate',
    data() {
        return {
            siteNo: "",
            layoutInfo: {
                layoutName:"",
                useYn:"Y",
                layoutHeader:"",
                layoutFooter:"",
            },
        }
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
    },
     methods: {
        insertLayout() {
            this.logger.debug(this, "insertLayout()");

            if(!confirm("등록 하시겠습니까?")) {
                return;
            }
    
            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }

                    this.layoutInfo.siteNo = this.siteNo;

                    axios
                        .post(this.config.contextRoot + "/layout/insertLayout.do", this.layoutInfo)
                        .then((response) => {
                            if(response.data.layoutNo) {
                                alert("등록 되었습니다.");
                                this.$router.replace(this.config.contextRoot + '/site/' + this.siteNo + '/layout/' + response.data.layoutNo + '/edit');
                            }
                        })
                        .catch((error) => {
                            this.exceptionHandler(this, error);
                        });

                }).catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.errors.all());
                    return false;
                })

        },
  
        reset() {
            if(confirm('취소 하시겠습니까?')){
                this.$router.go(this.$router.currentRoute);
            }
        }
    }
}
</script>

<style>

</style>