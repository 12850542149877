var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contentLayout"},[_vm._m(0),_c('div',{staticClass:"contentBody"},[_c('h3',{staticClass:"contentTitle"},[_vm._v("게시판 수정")]),_c('div',{staticClass:"infoWrap"},[_vm._m(1),_c('table',{staticClass:"infoTable"},[_c('tbody',[_c('tr',[_c('th',{staticClass:"requiredMark"},[_vm._v("게시판 명")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.boardInfo.contentsTitle),expression:"boardInfo.contentsTitle"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"inputBox",attrs:{"type":"text","data-vv-name":"contentsTitle","data-vv-as":"게시판명"},domProps:{"value":(_vm.boardInfo.contentsTitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.boardInfo, "contentsTitle", $event.target.value)}}}),(_vm.errors.has('contentsTitle'))?_c('p',{staticClass:"validation-text"},[_vm._v(" * "+_vm._s(_vm.errors.first("contentsTitle"))+" ")]):_vm._e()])]),_c('tr',[_c('th',{staticClass:"requiredMark"},[_vm._v("스킨선택")]),_c('td',[_c('div',{staticClass:"inputBtnWrap"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.boardInfo.skinNo),expression:"boardInfo.skinNo"}],staticClass:"selectBox",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.boardInfo, "skinNo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.skinList),function(skin){return _c('option',{key:skin.skinNo,domProps:{"value":skin.skinNo}},[_vm._v(" "+_vm._s(skin.skinName)+" ")])}),0),_c('router-link',{staticClass:"squareBtn bgLightGray",attrs:{"to":{
                                        path:
                                            _vm.config.contextRoot +
                                            '/site/' +
                                            _vm.$route.params.siteNo +
                                            '/skin',
                                    }}},[_vm._v(" 스킨 관리 ")])],1)])]),_c('tr',[_c('th',[_vm._v("생성일자")]),_c('td',[_vm._v(_vm._s(_vm.boardInfo.contentsAddDt))])]),_c('tr',[_c('th',{staticClass:"requiredMark"},[_vm._v("공지기능")]),_c('td',[_c('div',{staticClass:"radioBoxWrap"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.boardInfo.boardNoticeUseYn),expression:"boardInfo.boardNoticeUseYn"}],staticClass:"radioBox",attrs:{"type":"radio","name":"testRadio-6","value":"Y"},domProps:{"checked":_vm._q(_vm.boardInfo.boardNoticeUseYn,"Y")},on:{"change":function($event){return _vm.$set(_vm.boardInfo, "boardNoticeUseYn", "Y")}}}),_c('span',[_vm._v("사용")])]),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.boardInfo.boardNoticeUseYn),expression:"boardInfo.boardNoticeUseYn"}],staticClass:"radioBox",attrs:{"type":"radio","name":"testRadio-6","value":"N"},domProps:{"checked":_vm._q(_vm.boardInfo.boardNoticeUseYn,"N")},on:{"change":function($event){return _vm.$set(_vm.boardInfo, "boardNoticeUseYn", "N")}}}),_c('span',[_vm._v("미사용")])])])])]),_c('tr',[_c('th',{staticClass:"requiredMark"},[_vm._v("댓글기능")]),_c('td',[_c('div',{staticClass:"radioBoxWrap"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.boardInfo.boardCommentUseYn
                                        ),expression:"\n                                            boardInfo.boardCommentUseYn\n                                        "}],staticClass:"radioBox",attrs:{"type":"radio","name":"testRadio-7","value":"Y"},domProps:{"checked":_vm._q(
                                            _vm.boardInfo.boardCommentUseYn
                                        ,"Y")},on:{"change":function($event){return _vm.$set(_vm.boardInfo, "boardCommentUseYn", "Y")}}}),_c('span',[_vm._v("사용")])]),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                            _vm.boardInfo.boardCommentUseYn
                                        ),expression:"\n                                            boardInfo.boardCommentUseYn\n                                        "}],staticClass:"radioBox",attrs:{"type":"radio","name":"testRadio-7","value":"N"},domProps:{"checked":_vm._q(
                                            _vm.boardInfo.boardCommentUseYn
                                        ,"N")},on:{"change":function($event){return _vm.$set(_vm.boardInfo, "boardCommentUseYn", "N")}}}),_c('span',[_vm._v("미사용")])])])])]),_c('tr',[_c('th',{staticClass:"requiredMark"},[_vm._v("사용여부")]),_c('td',[_c('div',{staticClass:"radioBoxWrap"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.boardInfo.useYn),expression:"boardInfo.useYn"}],staticClass:"radioBox",attrs:{"type":"radio","name":"testRadio-8","value":"Y"},domProps:{"checked":_vm._q(_vm.boardInfo.useYn,"Y")},on:{"change":function($event){return _vm.$set(_vm.boardInfo, "useYn", "Y")}}}),_c('span',[_vm._v("사용")])]),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.boardInfo.useYn),expression:"boardInfo.useYn"}],staticClass:"radioBox",attrs:{"type":"radio","name":"testRadio-8","value":"N"},domProps:{"checked":_vm._q(_vm.boardInfo.useYn,"N")},on:{"change":function($event){return _vm.$set(_vm.boardInfo, "useYn", "N")}}}),_c('span',[_vm._v("미사용")])])])])])])])]),_c('div',{staticClass:"contentButtonBar"},[_c('div',{staticClass:"buttonWrap floatLeft"},[_c('router-link',{staticClass:"radiusSmallBtn bgGray",attrs:{"to":{
                        path:
                            _vm.config.contextRoot +
                            '/site/' +
                            _vm.$route.params.siteNo +
                            '/board',
                    }}},[_vm._v(" 목록 ")])],1),_c('div',{staticClass:"buttonWrap floatRight"},[_c('button',{staticClass:"radiusSmallBtn bgNavy",on:{"click":function($event){return _vm.insertBoard()}}},[_vm._v(" 저장 ")]),_c('button',{staticClass:"radiusSmallBtn bgDark",on:{"click":function($event){return _vm.deleteBoard()}}},[_vm._v(" 삭제 ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contentNavigationBar"},[_c('h2',{staticClass:"title"},[_vm._v("게시판")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"requireText"},[_vm._v("("),_c('em',[_vm._v("*")]),_vm._v(") 항목은 필수 항목입니다.")])}]

export { render, staticRenderFns }