<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">방문자 통계</h2>
        </div>
        <div class="contentBody">
            <h3 class="contentTitle">방문현황</h3>
            <table class="infoTable tableFixed">
                <thead>
                    <tr>
                        <th class="textCenter">방문자수</th>
                        <th class="textCenter">방문횟수</th>
                        <th class="textCenter">신규방문자수</th>
                        <th class="textCenter">재방문자수</th>
                        <th class="textCenter">방문당PV</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="textCenter">
                            {{ visitorStatsInfo.visitorCount | makeComma }}
                        </td>
                        <td class="textCenter">
                            {{ visitorStatsInfo.visitCount | makeComma }}
                        </td>
                        <td class="textCenter">
                            {{ visitorStatsInfo.newVisitorCount | makeComma }}
                        </td>
                        <td class="textCenter">
                            {{ visitorStatsInfo.reVisitorCount | makeComma }}
                        </td>
                        <td class="textCenter">
                            {{ visitorStatsInfo.visitorPageView | makeComma }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="contentTitleWrap marginTop-50">
                <h3 class="contentTitle">방문현황 그래프</h3>
                <div class="titleBtnWrap">
                    <button
                        :class="[
                            'titleRadiusBtn',
                            chartTab[0] == 1 ? 'bgType2' : 'bgType1',
                        ]"
                        @click="setDayChart()"
                    >
                        일간
                    </button>
                    <button
                        :class="[
                            'titleRadiusBtn',
                            chartTab[1] == 1 ? 'bgType2' : 'bgType1',
                        ]"
                        @click="setYearmonthChart()"
                    >
                        월간
                    </button>
                    <button
                        :class="[
                            'titleRadiusBtn',
                            chartTab[2] == 1 ? 'bgType2' : 'bgType1',
                        ]"
                        @click="setYearChart()"
                    >
                        년간
                    </button>
                </div>
                <div style="height: 500px">
                    <VueApexCharts
                        v-if="isShowChart"
                        width="100%"
                        height="500"
                        type="line"
                        :options="options"
                        :series="series"
                    ></VueApexCharts>
                </div>
            </div>
            <table class="infoTable tableFixed">
                <tbody></tbody>
            </table>
            <!-- <h3 class="contentTitle marginTop-50">인기 페이지</h3>
            <table class="infoTable tableFixed">
                <tbody></tbody>
            </table>
            <h3 class="contentTitle marginTop-50">방문자 정보</h3>
            <table class="infoTable tableFixed">
                <tbody></tbody>
            </table> -->
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";

/*Chart*/
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

export default {
    name: "VisitorStats",
    components: {
        VueApexCharts,
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.selectVisitorStatsInfo();
    },
    data() {
        return {
            siteNo: "",
            visitorStatsInfo: {
                visitorCount: "",
                visitCount: "",
                newVisitorCount: "",
                reVisitorCount: "",
                visitorPageView: "",
                visitDayChart: [],
                visitYearmonthChart: [],
                visitYearChart: [],
            },
            options: {
                chart: {
                    id: "vuechart-example",
                    animations: {
                        enabled: false,
                    },
                },
            },
            series: [
                {
                    name: "",
                    data: [],
                },
            ],
            isShowChart: false,
            chartTab: [1, 0, 0],
        };
    },
    methods: {
        selectVisitorStatsInfo() {
            axios
                .post(
                    this.config.contextRoot +
                        "/visitor/selectVisitorStatsInfo.do",
                    { siteNo: this.siteNo }
                )
                .then((response) => {
                    this.visitorStatsInfo = response.data.visitorStatsInfo;

                    //기본 세팅 일간차트
                    this.setDayChart();
                });
        },
        setDayChart() {
            new Promise((resolve) => {
                this.chartTab = [1, 0, 0];
                this.isShowChart = false;
                this.$set(
                    this.series[0],
                    "data",
                    this.visitorStatsInfo.visitDayChart
                );
                resolve();
            }).then(() => {
                this.isShowChart = true;
            });
        },
        setYearmonthChart() {
            new Promise((resolve) => {
                this.chartTab = [0, 1, 0];
                this.isShowChart = false;
                this.$set(
                    this.series[0],
                    "data",
                    this.visitorStatsInfo.visitYearmonthChart
                );
                resolve();
            }).then(() => {
                this.isShowChart = true;
            });
        },
        setYearChart() {
            new Promise((resolve) => {
                this.chartTab = [0, 0, 1];
                this.isShowChart = false;
                this.$set(
                    this.series[0],
                    "data",
                    this.visitorStatsInfo.visitYearChart
                );
                resolve();
            }).then(() => {
                this.isShowChart = true;
            });
        },
    },
};
</script>

<style>
</style>