export default class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
        this.fileList = null;
    }

    upload() {
        return this.loader.file.then(file => new Promise((resolve, reject) => {
            this._initRequest();
            this._initListeners(resolve, reject);
            console.log(file)
            this._sendRequest(file);
        }))
    }

    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();
        xhr.open('POST', '/common/file/upload.do', true);
        xhr.responseType = 'json';
    }

    _initListeners(resolve, reject) {
        const xhr = this.xhr;
        const genericErrorText = '파일을 업로드 할 수 없습니다.'

        xhr.addEventListener('error', () => { reject(genericErrorText) })
        xhr.addEventListener('abort', () => reject())
        xhr.addEventListener('load', () => {
            const response = xhr.response
            console.log(response)
            if (!response || response.error) {
                return reject(response && response.error ? response.error.message : genericErrorText);
            }
            this.fileList = response.fileList;
            resolve({
                default: "/common/file/imgLoad.do?fileGroupNo=tmp&serverFileSaveName=" + response.fileList[0].serverFileSaveName, //업로드된 파일 주소

            })

        })
    }

    _sendRequest(file) {
        const data = new FormData()
        data.append('upload', file)
        this.xhr.send(data)
    }
}