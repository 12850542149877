<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">컨텐츠 관리</h2>
        </div>
        <div class="contentBody">
            <div class="contentTitleWrap">
                <h3 class="contentTitle">컨텐츠 수정</h3>
                <div class="titleBtnWrap">
                    <button
                        class="radiusInputBtn"
                        type="button"
                        @click="openPopup"
                    >
                        컨텐츠 이력
                    </button>
                </div>
            </div>

            <!-- 입력 폼 -->
            <div class="infoWrap">
                <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
                <table class="infoTable marginBottom-35">
                    <tbody>
                        <tr>
                            <th class="requiredMark" style="width: 140px">
                                컨텐츠 제목
                            </th>
                            <td>
                                <input
                                    class="inputBox"
                                    name="contentsTitle"
                                    v-validate="'required'"
                                    data-vv-name="contentsTitle"
                                    data-vv-as="컨텐츠 제목"
                                    placeholder="컨텐츠 제목을 입력해주세요."
                                    v-model="contentsInfo.contentsTitle"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('contentsTitle')"
                                >
                                    * {{ errors.first("contentsTitle") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">사용 여부</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="contentsInfo.useYn"
                                            value="Y"
                                        />
                                        <span>사용</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="contentsInfo.useYn"
                                            value="N"
                                        />
                                        <span>미사용</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">검색 허용 여부</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-2"
                                            v-model="contentsInfo.searchAllowYn"
                                            value="Y"
                                        />
                                        <span>사용</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-2"
                                            v-model="contentsInfo.searchAllowYn"
                                            value="N"
                                        />
                                        <span>미사용</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">내용</th>
                            <td>
                                <ckeditor
                                    v-validate="'required'"
                                    data-vv-name="articleContents"
                                    data-vv-as="내용"
                                    placeholder="내용을 입력해주세요."
                                    v-model="contentsInfo.articleContents"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/contents',
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        목록
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgNavy"
                        @click="updateContents()"
                    >
                        저장
                    </button>
                    <button
                        class="radiusSmallBtn bgDark"
                        @click="deleteContents()"
                    >
                        삭제
                    </button>
                </div>
            </div>

            <!-- 이력 팝업 -->
            <div class="popupWrap" id="roleListPopup" v-show="isShowPopup">
                <div class="popupBackground"></div>
                <div class="popup contentPopup">
                    <div class="popupHead">
                        <h2 class="popupTitle">컨텐츠 이력</h2>
                        <button class="popupClose">
                            <img
                                src="@/assets/images/component/popup-close.png"
                                alt="팝업 닫기"
                                @click="closePopup()"
                            />
                        </button>
                    </div>

                    <!-- 팝업 페이지 1 -->
                    <div
                        class="popupPage popupPage-1"
                        v-show="popupPage[0] == 1"
                    >
                        <div class="popupBody">
                            <div class="contentTitleWrap">
                                <h3 class="contentTitle">컨텐츠 이력 목록</h3>
                            </div>

                            <table class="listTable">
                                <thead>
                                    <tr>
                                        <th>번호</th>
                                        <th>컨텐츠명</th>
                                        <th>이력일시</th>
                                    </tr>
                                </thead>
                                <tbody
                                    v-for="history in historyList"
                                    :key="history.historyNo"
                                    @click="
                                        showHistoryDetail(history.historyNo)
                                    "
                                >
                                    <tr>
                                        <td>{{ history.rowNumber }}</td>
                                        <td>
                                            <a href="#" @click.prevent="">{{
                                                history.articleTitle
                                            }}</a>
                                        </td>
                                        <td>{{ history.historyDate }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div
                                class="pagination marginTop-50"
                                v-if="pageNoList && pageNoList.length > 0"
                            >
                                <ul>
                                    <li>
                                        <a
                                            href="#"
                                            @click.prevent="doSearchPage(1)"
                                            title="첫 페이지"
                                        >
                                            <img
                                                src="@/assets/images/component/pagination-first.png"
                                                alt="첫 페이지"
                                            />
                                        </a>
                                    </li>
                                    <li v-show="isPrev">
                                        <a
                                            href="#"
                                            @click.prevent="
                                                doSearchPage(startPageNo - 1)
                                            "
                                            :title="
                                                '이전' +
                                                displayPageCount +
                                                '페이지'
                                            "
                                        >
                                            <img
                                                src="@/assets/images/component/pagination-prev.png"
                                                :alt="
                                                    '이전' +
                                                    displayPageCount +
                                                    '페이지'
                                                "
                                            />
                                        </a>
                                    </li>
                                    <li
                                        v-for="pageNo in pageNoList"
                                        :key="pageNo"
                                    >
                                        <a
                                            href="#"
                                            :class="
                                                pagination.current_page ==
                                                pageNo
                                                    ? 'active'
                                                    : ''
                                            "
                                            @click.prevent="
                                                doSearchPage(pageNo)
                                            "
                                        >
                                            {{ pageNo }}
                                        </a>
                                    </li>
                                    <li v-show="isNext">
                                        <a
                                            href="#"
                                            @click.prevent="
                                                doSearchPage(endPageNo + 1)
                                            "
                                            :title="
                                                '다음' +
                                                displayPageCount +
                                                '페이지'
                                            "
                                        >
                                            <img
                                                src="@/assets/images/component/pagination-next.png"
                                                :alt="
                                                    '다음' +
                                                    displayPageCount +
                                                    '페이지'
                                                "
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            @click.prevent="
                                                doSearchPage(realEndPageNo)
                                            "
                                            title="마지막 페이지"
                                        >
                                            <img
                                                src="@/assets/images/component/pagination-last.png"
                                                alt="마지막 페이지"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- 팝업 페이지 2 -->
                    <div
                        class="popupPage popupPage-2"
                        v-show="popupPage[1] == 1"
                    >
                        <div class="popupBody">
                            <div class="contentTitleWrap">
                                <h3 class="contentTitle">
                                    <small style="font-weight: 300">
                                        일시:
                                        {{ historyDetail.historyDate }}
                                    </small>
                                </h3>

                                <div class="titleBtnWrap">
                                    <button
                                        type="button"
                                        class="titleRadiusBtn bgType1"
                                        @click="showHistoryList"
                                    >
                                        목록
                                    </button>
                                </div>
                            </div>
                            <div style="height: 500px; overflow: auto">
                                <div class="sectionLabel marginBottom-15">
                                    <strong class="sectionLabelTitle">
                                        {{historyDetail.articleTitle}}
                                    </strong>
                                </div>
                                <div class="sectionLabel">
                                    <strong class="sectionLabelTitle">
                                        내용
                                    </strong>
                                </div>
                                <textarea
                                    readonly
                                    class="
                                        textArea
                                        borderDarkGray
                                        marginBottom-15
                                    "
                                    :value="historyDetail.articleContents"
                                >
                                </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ckeditor from "@/components/common/ckEditor.vue";
import EventBus from "../../script/EventBus";
export default {
    name: "ContentsEdit",
    data() {
        return {
            siteNo: "",
            contentsNo: "",
            contentsInfo: {
                fileList: [],
            },
            // 이력 팝업 관련..
            isShowPopup: false,
            popupPage: [1, 0],
            historyDetail: {},
            historyList: [],
            pagination: {},
            displayPageCount: 10,
            pageNoList: [],
        };
    },
    components: {
        ckeditor,
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.contentsNo = this.$route.params.contentsNo;
        this.selectContents();
        EventBus.$on("addFile", (fileList) => {
            this.logger.debug(this, "eventBus / addFile()");
            this.addFile(fileList);
        });
    },
    methods: {
        // 이력 팝업 관련..
        doSearchPage(pageNo) {
            this.logger.debug(this, "doSearchPage()");
            if (typeof pageNo === "undefined") {
                pageNo = 1;
            }
            this.selectHistoryList(pageNo);
        },
        calcPagination() {
            this.logger.debug(this, "calcPagination()");
            this.endPageNo =
                Math.ceil(
                    this.pagination.current_page / this.displayPageCount
                ) * this.displayPageCount;
            this.startPageNo = this.endPageNo - this.displayPageCount + 1;

            this.realEndPageNo = Math.ceil(
                this.pagination.total / this.pagination.per_page
            );

            if (this.startPageNo < 0) {
                this.startPageNo = 1;
            }
            if (this.endPageNo > this.realEndPageNo) {
                this.endPageNo = this.realEndPageNo;
            }

            this.isPrev = this.startPageNo == 1 ? false : true;
            this.isNext =
                this.endPageNo * this.pagination.per_page >=
                this.pagination.total
                    ? false
                    : true;

            this.pageNoList = [];
            for (let i = this.startPageNo; i <= this.endPageNo; i++) {
                this.pageNoList.push(i);
            }
        },
        showHistoryList() {
            this.popupPage = [1, 0];
        },
        showHistoryDetail(historyNo) {
            this.selectHistory(historyNo).then(() => {
                this.popupPage = [0, 1];
            });
        },
        closePopup() {
            this.isShowPopup = false;
            this.popupPage = [1, 0];
        },
        openPopup() {
            this.selectHistoryList().then(() => {
                this.isShowPopup = true;
            });
        },
        selectHistory(historyNo) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        this.config.contextRoot + "/contents/selectHistory.do",
                        {
                            historyNo: historyNo,
                        }
                    )
                    .then((response) => {
                        this.historyDetail = response.data.history;
                        resolve();
                    })
                    .catch((error) => {
                        this.exceptionHandler(this, error);
                        reject();
                    });
            });
        },
        selectHistoryList(pageNo) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        this.config.contextRoot +
                            "/contents/selectHistoryList.do",
                        {
                            articleNo: this.contentsInfo.articleNo,
                            pageNo: pageNo ? pageNo : 1,
                        }
                    )
                    .then((response) => {
                        this.historyList = response.data.historyList;
                        this.pagination = response.data.pagination;
                        this.calcPagination();
                        resolve();
                    })
                    .catch((error) => {
                        this.exceptionHandler(this, error);
                        reject();
                    });
            });
        },
        // 컨텐츠 수정 관련..
        selectContents() {
            this.logger.debug(this, "selectContents()");
            axios
                .post(this.config.contextRoot + "/contents/selectContents.do", {
                    contentsNo: this.contentsNo,
                })
                .then((response) => {
                    this.contentsInfo = response.data.contentsInfo;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        updateContents() {
            this.logger.debug(this, "updateContents()");

            if (!confirm("수정 하시겠습니까?")) {
                return;
            }

            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }

                    axios
                        .post(
                            this.config.contextRoot +
                                "/contents/updateContents.do",
                            this.contentsInfo
                        )
                        .then((response) => {
                            if (response.status == 200) {
                                alert("수정 되었습니다.");
                            }
                        })
                        .catch((error) => {
                            this.exceptionHandler(this, error);
                        });
                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.errors.all());
                    return false;
                });
        },
        deleteContents() {
            if (!confirm("삭제 하시겠습니까?")) {
                return;
            }

            const formData = {
                contentsNo: this.contentsInfo.contentsNo,
            };
            axios
                .post(
                    this.config.contextRoot + "/contents/deleteContents.do",
                    formData
                )
                .then((response) => {
                    if (response.status == 200) {
                        alert("삭제 되었습니다.");
                        this.$router.replace(
                            this.config.contextRoot +
                                "/site/" +
                                this.siteNo +
                                "/contents"
                        );
                    }
                })
                .catch((error) => {
                    if (
                        error.response.data.message ==
                        "SQLIntegrityConstraintViolationException"
                    ) {
                        alert(
                            "해당 컨텐츠는 메뉴관리에서 사용 중이므로 삭제할 수 없습니다."
                        );
                        return;
                    }

                    this.exceptionHandler(this, error);
                });
        },
        addFile(fileList) {
            this.articleInfo.fileList =
                this.articleInfo.fileList.concat(fileList);
        },
    },
};
</script>

<style>
</style>