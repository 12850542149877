<template>
<div id="app">
  <ckeditor :editor="editor" v-model="editorData" :value="value" @input="myChange" :config="editorConfig" />
</div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import CKEditor from '@ckeditor/ckeditor5-vue2' 
import UploadAdapter from './UploadAdapter.js';
import EventBus from '../../script/EventBus.js';

export default 
{ 
    name: 'app', 
    components: { 
        ckeditor: CKEditor.component 
        }, 
        props:{
            value:{

            }
        },
updated(){
    this.editorData = this.$props.value;
},

  data() {
        return {
        editor: ClassicEditor,
        editorData :  "",
        editorConfig: { // The configuration of the editor. 

			toolbar: {
			items: [
				'heading',
				'|',
				'bold',
				'italic',
				'link',
				'bulletedList',
				'numberedList',
				'|',
				'outdent',
				'indent',
				'|',
				'imageUpload',
				'blockQuote',
				'insertTable',
				'mediaEmbed',
				'undo',
				'redo',
				'htmlEmbed'
			],
		},
		htmlEmbed: {
            showPreviews: true,
            sanitizeHtml: ( inputHtml ) => {
                // Strip unsafe elements and attributes, e.g.:
                // the `<script>` elements and `on*` attributes.
                const outputHtml = ( inputHtml );

                return {
                    html: outputHtml,
                    // true or false depending on whether the sanitizer stripped anything.
                    hasChanged: true
                };
            }
        },
		language: 'ko',
        height:500,
		image: {
			toolbar: [
				'imageTextAlternative',
				'imageStyle:full',
				'imageStyle:side'
			]
		},
		table: {
			contentToolbar: [
				'tableColumn',
				'tableRow',
				'mergeTableCells'
			]
		},
		licenseKey: '',
		extraPlugins: [this.uploader],
        },

    }
  },
  
  
methods:{
    uploader(editor) {
        editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                        const ua =  new UploadAdapter( loader );
						let interval = setInterval(()=>{
							if ( ua.fileList ) {
								console.log(ua.fileList)
								EventBus.$emit('addFile', ua.fileList);
								clearInterval(interval);
							}
						})
						
						return ua;
						
        };
    },
    myChange(){
         this.$emit('input', this.editorData);
    }
}
  
}

</script>

<style>
    .ck.ck-editor__editable{
        min-height: 500px;
    }
</style>