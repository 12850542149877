<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">컨텐츠 관리</h2>
        </div>
        <div class="contentBody">
            <h3 class="contentTitle">컨텐츠 등록</h3>

            <!-- 입력 폼 -->
            <div class="infoWrap">
                <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
                <table class="infoTable marginBottom-35">
                    <tbody>
                        <tr>
                            <th class="requiredMark" style="width: 140px">
                                컨텐츠 제목
                            </th>
                            <td>
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="contentsInfo.contentsTitle"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">사용 여부</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="contentsInfo.useYn"
                                            value="Y"
                                        />
                                        <span>사용</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            v-model="contentsInfo.useYn"
                                            value="N"
                                        />
                                        <span>미사용</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">검색 허용 여부</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-2"
                                            v-model="contentsInfo.searchAllowYn"
                                            value="Y"
                                        />
                                        <span>사용</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-2"
                                            v-model="contentsInfo.searchAllowYn"
                                            value="N"
                                        />
                                        <span>미사용</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">내용</th>
                            <td>
                                <ckeditor
                                    v-validate="'required'"
                                    data-vv-name="articleContents"
                                    data-vv-as="내용"
                                    placeholder="내용을 입력해주세요."
                                    v-model="contentsInfo.articleContents"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/contents',
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        목록
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgNavy"
                        @click="insertContents()"
                    >
                        저장
                    </button>
                    <button class="radiusSmallBtn bgDark" @click="reset()">
                        취소
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ckeditor from "@/components/common/ckEditor.vue";
import EventBus from "../../script/EventBus";
export default {
    name: "ContentsCreate",
    data() {
        return {
            siteNo: "",
            contentsNo: "",
            contentsInfo: {
                useYn: "Y",
                searchAllowYn: "Y",
                fileList: [],
            },
        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.contentsNo = this.$route.params.contentsNo;

        EventBus.$on("addFile", (fileList) => {
            this.logger.debug(this, "eventBus / addFile()");
            this.addFile(fileList);
        });
    },
    components: {
        ckeditor,
    },
    methods: {
        insertContents() {
            this.logger.debug(this, "insertContents()");

            if (!confirm("등록 하시겠습니까?")) {
                return;
            }

            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }

                    axios
                        .post(
                            this.config.contextRoot +
                                "/contents/insertContents.do",
                            this.contentsInfo
                        )
                        .then((response) => {
                            if (response.status == 200) {
                                alert("등록 되었습니다.");
                                this.$router.replace(
                                    this.config.contextRoot +
                                        "/site/" +
                                        this.siteNo +
                                        "/contents/" +
                                        response.data.contentsNo +
                                        "/edit"
                                );
                            }
                        })
                        .catch((error) => {
                            this.exceptionHandler(this, error);
                        });
                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.errors.all());
                    return false;
                });
        },
        reset() {
            if (confirm("취소 하시겠습니까?")) {
                this.$router.go(this.$router.currentRoute);
            }
        },
        addFile(fileList) {
            this.articleInfo.fileList =
                this.articleInfo.fileList.concat(fileList);
        },
    },
};
</script>

<style>
</style>