<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">기업회원관리</h2>
        </div>
        <div class="contentBody">
            <h3 class="contentTitle">회원사신청 정보</h3>

            <!-- 입력 폼 -->
            <div class="infoWrap">
                <table
                    class="infoTable tableFixed marginBottom-35"
                    style="border: 2px solid #ccc"
                >
                    <tbody>
                        <tr>
                            <th style="width: 140px" class="requiredMark">
                                기업명
                            </th>
                            <td>
                                <input
                                    type="text"
                                    v-model="companyInfo.companyName"
                                    class="inputBox"
                                    data-vv-name="companyName"
                                    data-vv-as="기업명"
                                    v-validate="'required|max:50'"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('companyName')"
                                >
                                    * {{ errors.first("companyName") }}
                                </p>
                            </td>
                            <th style="width: 140px" class="requiredMark">
                                사업자 등록번호
                            </th>
                            <td>
                                <input
                                    type="text"
                                    v-model="companyInfo.businessNo"
                                    class="inputBox"
                                    data-vv-name="businessNo"
                                    data-vv-as="사업자 등록번호"
                                    v-validate="'required|max:12'"
                                />

                                <p
                                    class="validation-text"
                                    v-if="errors.has('businessNo')"
                                >
                                    * {{ errors.first("businessNo") }}
                                </p>
                            </td>
                            <th style="width: 140px" class="requiredMark">
                                사업 분야
                            </th>
                            <td>
                                <input
                                    type="text"
                                    v-model="companyInfo.businessCategory"
                                    class="inputBox"
                                    data-vv-name="businessCategory"
                                    data-vv-as="사업 분야"
                                    v-validate="'required|max:50'"
                                />

                                <p
                                    class="validation-text"
                                    v-if="errors.has('businessCategory')"
                                >
                                    * {{ errors.first("businessCategory") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">대표이사</th>
                            <td>
                                <input
                                    type="text"
                                    v-model="companyInfo.companyCeoName"
                                    class="inputBox"
                                    data-vv-name="companyCeoName"
                                    data-vv-as="대표이사"
                                    v-validate="'required|max:50'"
                                />

                                <p
                                    class="validation-text"
                                    v-if="errors.has('companyCeoName')"
                                >
                                    * {{ errors.first("companyCeoName") }}
                                </p>
                            </td>
                            <th class="requiredMark">대표번호</th>
                            <td>
                                <input
                                    type="text"
                                    v-model="companyInfo.companyTelNo"
                                    class="inputBox"
                                    data-vv-name="companyTelNo"
                                    data-vv-as="대표번호"
                                    v-validate="'required|max:15'"
                                />

                                <p
                                    class="validation-text"
                                    v-if="errors.has('companyTelNo')"
                                >
                                    * {{ errors.first("companyTelNo") }}
                                </p>
                            </td>
                            <th class="requiredMark">기업 홈페이지</th>
                            <td>
                                <input
                                    type="text"
                                    v-model="companyInfo.companySiteAddress"
                                    class="inputBox"
                                    data-vv-name="companySiteAddress"
                                    data-vv-as="기업 홈페이지"
                                    v-validate="'required|max:400'"
                                />

                                <p
                                    class="validation-text"
                                    v-if="errors.has('companySiteAddress')"
                                >
                                    * {{ errors.first("companySiteAddress") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 140px" class="requiredMark">
                                담당자 이름
                            </th>
                            <td>
                                <input
                                    type="text"
                                    v-model="companyInfo.managerName"
                                    class="inputBox"
                                    data-vv-name="managerName"
                                    data-vv-as="담당자 이름"
                                    v-validate="'required|max:50'"
                                />

                                <p
                                    class="validation-text"
                                    v-if="errors.has('managerName')"
                                >
                                    * {{ errors.first("managerName") }}
                                </p>
                            </td>
                            <th style="width: 140px" class="requiredMark">
                                담당자 연락처
                            </th>
                            <td>
                                <input
                                    type="text"
                                    v-model="companyInfo.managerTelNo"
                                    class="inputBox"
                                    data-vv-name="managerTelNo"
                                    data-vv-as="담당자 연락처"
                                    v-validate="'required|max:15'"
                                />

                                <p
                                    class="validation-text"
                                    v-if="errors.has('managerTelNo')"
                                >
                                    * {{ errors.first("managerTelNo") }}
                                </p>
                            </td>
                            <th style="width: 140px" class="requiredMark">
                                담당자 이메일
                            </th>
                            <td>
                                <input
                                    type="text"
                                    v-model="companyInfo.managerEmailAddress"
                                    class="inputBox"
                                    data-vv-name="managerEmailAddress"
                                    data-vv-as="담당자 이메일"
                                    v-validate="'required|max:100'"
                                />

                                <p
                                    class="validation-text"
                                    v-if="errors.has('managerEmailAddress')"
                                >
                                    * {{ errors.first("managerEmailAddress") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">신청 내용</th>
                            <td colspan="5">
                                <textarea
                                    v-model="companyInfo.applicationContents"
                                    class="textArea"
                                    data-vv-name="applicationContents"
                                    data-vv-as="신청 내용"
                                    v-validate="'required'"
                                ></textarea>

                                <p
                                    class="validation-text"
                                    v-if="errors.has('applicationContents')"
                                >
                                    * {{ errors.first("applicationContents") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">첨부파일</th>
                            <td colspan="5">
                                <common-file
                                    ref="FileUploader"
                                    v-model="companyInfo.fileList"
                                    style="display: none"
                                />

                                <table style="width: 100%">
                                    <tr>
                                        <td style="width: 80px">
                                            <strong>로고여부</strong>
                                        </td>
                                        <td><strong>파일</strong></td>
                                    </tr>
                                    <tr
                                        class="fileListItem marginBottom-10"
                                        v-for="(
                                            file, index
                                        ) in companyInfo.fileList"
                                        :key="file.serverFileSaveName"
                                    >
                                        <td style="text-align: center">
                                            <input
                                                type="radio"
                                                v-model="logoYnCheck"
                                                :value="file"
                                                @change="changeLogoYn(file, $event)"
                                                style="
                                                    vertical-align: middle;
                                                    margin-right: 5px;
                                                "
                                            />
                                        </td>
                                        <td>
                                            <a
                                                href="#"
                                                class="
                                                    fileDownLink
                                                    marginRight-10
                                                "
                                                @click.prevent="doDownload(file)"
                                                download
                                                >{{ file.fileName }}</a
                                            >
                                            <span
                                                class="fileSize marginRight-10"
                                                >{{ file.fileSizeName }}</span
                                            >
                                            <button
                                                class="squareBtn size-50"
                                                @click="deleteFile(index)"
                                            >
                                                삭제
                                            </button>
                                        </td>
                                    </tr>
                                </table>

                                <div class="inputBtnWrap">
                                    <button
                                        class="squareBtn bgLightGray"
                                        type="button"
                                        @click="openUploader('FileUploader')"
                                    >
                                        파일선택
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">회원사 등급</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label
                                        v-for="role in companyRoleList"
                                        :key="role.roleNo"
                                    >
                                        <input
                                            type="radio"
                                            v-model="companyInfo.roleNoList[0]"
                                            :value="role.roleNo"
                                            class="radioBox"
                                        />
                                        <span>{{ role.roleDiscription }}</span>
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/companyApply',
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        목록
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgNavy"
                        @click="updateCompany()"
                    >
                        저장
                    </button>
                    <button
                        class="radiusSmallBtn bgDark"
                        @click="deleteCompany()"
                    >
                        삭제
                    </button>
                </div>
            </div>
            <RolePopup v-if="openPopupStatus" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import RolePopup from "@/components/role/RolePopup";
import EventBus from "@/script/EventBus.js";
import CommonFile from "@/components/common/CommonFile";

export default {
    name: "CompanyView",
    components: {
        RolePopup,
        CommonFile,
    },
    data() {
        return {
            siteNo: "",
            roleList: [],
            openPopupStatus: false,
            companyNo: "",
            companyInfo: {
                fileList: [],
            },
            companyRoleList: [],
            selectRole: "",
            logoYnCheck: {},
        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.companyInfoNo = this.$route.params.companyInfoNo;
        this.selectCompany();
        EventBus.$on("popupOpenStatus", () => {
            this.openPopupStatus = false;
        });
    },
    watch: {
        logoYnCheck(fileObj) {
            for(let file of this.companyInfo.fileList) {
                file.logoYn = "N";
            }
            fileObj.logoYn = "Y";
        }
    },
    methods: {
        changeLogoYn(file, $event) {
            console.log(file, $event)
        },
        openUploader(refName) {
            this.logger.debug(this, "openUploader()");
            this.$refs[refName].openUploader();
        },
        doDownload(data) {
            this.logger.debug(this, "doDownload");
            const formData = new FormData();
            formData.append("fileGroupNo", data.fileGroupNo);
            formData.append("fileName", data.fileName);
            formData.append("serverFileSaveName", data.serverFileSaveName);
            axios
                .post(
                    "/common/file/download.do",
                    formData,
                    { responseType: "blob" }
                )
                .then((response) => {
                    console.log(response);
                    const url = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: response.headers["content-type"],
                        })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", data.fileName);
                    document.body.appendChild(link);
                    link.click();
                    location.reload();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        deleteFile(index) {
            this.companyInfo.fileList.splice(index, 1);
        },
        addFile(fileList) {
            this.companyInfo.fileList =
                this.companyInfo.fileList.concat(fileList);
        },
        selectCompany() {
            this.logger.debug(this, "selectCompany()");
            axios
                .post(
                    this.config.contextRoot + "/company/selectCompanyInfo.do",
                    {
                        companyInfoNo: this.companyInfoNo,
                    }
                )
                .then((response) => {
                    this.companyInfo = response.data.companyInfo;
                    this.roleList = response.data.roleList; // 전체 권한 목록

                    this.selectRole = this.companyInfo.roleNoList[0];

                    this.companyRoleList = this.roleList.filter((role) => {
                        if (role.roleNo != 1 && role.roleNo != 3) {
                            return true;
                        }
                        return false;
                    });

                    for ( let file of this.companyInfo.fileList) {
                        if (file.logoYn == "Y") {
                            this.logoYnCheck = file;
                        }
                    }

                    if ( Object.keys(this.logoYnCheck).length == 0 ) {
                        this.logoYnCheck = this.companyInfo.fileList[0];
                    }

                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });

            
        },
        openPopup() {
            this.openPopupStatus = true;
        },
        updateCompany() {
            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }

                    if (this.companyInfo.fileList.length == 0) {
                        alert("로고파일을 업로드 하세요.");
                        return;
                    }

                    if (
                        this.companyInfo.fileList.length > 0 &&
                        this.companyInfo.fileList.filter(
                            (obj) => obj.logoYn == "Y"
                        ).length == 0
                    ) {
                        alert("로고여부를 선택해 주세요.");
                        return;
                    }

                    if (!confirm("수정 하시겠습니까?")) {
                        return;
                    }

                    axios
                        .post(
                            this.config.contextRoot +
                                "/company/updateCompanyInfo.do",
                            this.companyInfo
                        )
                        .then((response) => {
                            if (response.status == 200) {
                                alert("수정 되었습니다.");
                            }
                        })
                        .catch((error) => {
                            this.exceptionHandler(this, error);
                        });
                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.error.all());
                    return false;
                });
        },
        deleteCompany() {
            if (!confirm("삭제 하시겠습니까?")) {
                return;
            }
            axios
                .post(
                    this.config.contextRoot + "/company/deleteCompany.do",
                    this.companyInfo
                )
                .then((response) => {
                    if (response.status == 200) {
                        alert("삭제 되었습니다.");
                        this.$router.replace(
                            this.config.contextRoot +
                                "/site/" +
                                this.siteNo +
                                "/companyApply"
                        );
                    }
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
    },
};
</script>

<style>
</style>