<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">게시글 관리</h2>
        </div>
        <div class="contentBody">
            <!-- 검색 -->
            <div class="searchBar">
                <div class="columnDivision-3">
                    <div class="labelWrap">
                        <label>게시글 제목</label>
                        <input
                            type="text"
                            class="inputBox"
                            v-model="searchForm.articleTitle"
                        />
                    </div>
                    <div class="labelWrap">
                        <label>작성일자</label>
                        <div class="datePicker">
                            <input
                                type="text"
                                class="inputBox"
                                placeholder="연도-월-일"
                                id="startDate"
                                v-model="searchForm.startDate"
                                autocomplete="off"
                            />
                            <div
                                ref="startDatePicker"
                                id="startDatePicker"
                            ></div>
                            <i></i>
                        </div>
                    </div>
                    <div class="labelWrap">
                        <label class="textCenter" style="width: 30px">-</label>
                        <div class="datePicker">
                            <input
                                type="text"
                                class="inputBox"
                                placeholder="연도-월-일"
                                id="endDate"
                                v-model="searchForm.endDate"
                                autocomplete="off"
                            />
                            <div ref="endDatePicker" id="endDatePicker"></div>
                            <i></i>
                        </div>
                    </div>
                </div>
                <button class="searchBtn" @click="doSearchPage(1)">
                    조회하기
                </button>
            </div>
            <div class="contentTitleWrap marginTop-35">
                <h3 class="contentTitle">게시글 목록</h3>
                <div class="titleSelectBoxWrap">
                    <select
                        class="selectBox"
                        v-model="searchForm.contentsNo"
                        @change.prevent="doSearchPage(1)"
                    >
                        <option
                            v-for="board in boardList"
                            :key="board.contentsNo"
                            :value="board.contentsNo"
                        >
                            {{ board.contentsTitle }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="listWrap">
                <!-- 목록 상단 -->
                <div class="listTop">
                    <span class="listTotal"
                        >(Total <em>{{ pagination.total }}</em
                        >)</span
                    >
                    <select
                        class="selectBox"
                        @change.prevent="doSearchPage(1)"
                        v-model="searchForm.countPerPage"
                    >
                        <option>5</option>
                        <option selected>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>50</option>
                        <option>100</option>
                    </select>
                </div>

                <!-- 목록테이블 -->
                <table class="listTable tableFixed">
                    <colgroup>
                        <col style="width: 60px" />
                        <col style="width: 80px" />
                        <col style="width: auto" />
                        <col style="width: 80px" />
                        <col style="width: 80px" />
                        <col style="width: 80px" />
                        <col style="width: 150px" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <label>
                                    <input
                                        type="checkbox"
                                        @click="checkAll"
                                        v-model="allChecked"
                                    />
                                </label>
                            </th>
                            <th>번호</th>
                            <th>게시글 제목</th>
                            <th>공지여부</th>
                            <th>댓글수</th>
                            <th>조회수</th>
                            <th>작성일자</th>
                        </tr>
                    </thead>
                    <tbody
                        v-for="article in articleList"
                        :key="article.articleNo"
                        @click="
                            $router.push({
                                path:
                                    config.contextRoot +
                                    '/site/' +
                                    $route.params.siteNo +
                                    '/article/' +
                                    article.articleNo +
                                    '/edit',
                                query: {
                                    contentsNo: article.contentsNo,
                                },
                            })
                        "
                    >
                        <tr>
                            <td>
                                <label
                                    ><input
                                        type="checkbox"
                                        v-model="deleteArticleNoList"
                                        :value="article.articleNo"
                                        @click.stop=""
                                    />
                                </label>
                            </td>
                            <td>{{ article.rowNumber }}</td>
                            <td class="textEllipsis textLeft">
                                {{ article.articleTitle }}
                            </td>
                            <td>
                                {{
                                    article.articleNoticeYn == "Y"
                                        ? "사용"
                                        : "미사용"
                                }}
                            </td>
                            <td>{{ article.commentCount }}</td>
                            <td>{{ article.articleReadCount }}</td>
                            <td>{{ article.articleAddDt }}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- 페이지네이션-->
                <div
                    class="pagination marginTop-50"
                    v-if="pageNoList && pageNoList.length > 0"
                >
                    <ul>
                        <li>
                            <a
                                href="#"
                                @click.prevent="doSearchPage(1)"
                                title="첫 페이지"
                            >
                                <img
                                    src="@/assets/images/component/pagination-first.png"
                                    alt="첫 페이지"
                                />
                            </a>
                        </li>
                        <li v-show="isPrev">
                            <a
                                href="#"
                                @click.prevent="doSearchPage(startPageNo - 1)"
                                :title="'이전' + displayPageCount + '페이지'"
                            >
                                <img
                                    src="@/assets/images/component/pagination-prev.png"
                                    :alt="'이전' + displayPageCount + '페이지'"
                                />
                            </a>
                        </li>
                        <li v-for="pageNo in pageNoList" :key="pageNo">
                            <a
                                href="#"
                                :class="
                                    pagination.current_page == pageNo
                                        ? 'active'
                                        : ''
                                "
                                @click.prevent="doSearchPage(pageNo)"
                            >
                                {{ pageNo }}
                            </a>
                        </li>
                        <li v-show="isNext">
                            <a
                                href="#"
                                @click.prevent="doSearchPage(endPageNo + 1)"
                                :title="'다음' + displayPageCount + '페이지'"
                            >
                                <img
                                    src="@/assets/images/component/pagination-next.png"
                                    :alt="'다음' + displayPageCount + '페이지'"
                                />
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                @click.prevent="doSearchPage(realEndPageNo)"
                                title="마지막 페이지"
                            >
                                <img
                                    src="@/assets/images/component/pagination-last.png"
                                    alt="마지막 페이지"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/article/create',
                            query: { contentsNo: searchForm.contentsNo },
                        }"
                        class="radiusSmallBtn bgBlue"
                    >
                        신규
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgTuftsBlue"
                        @click.prevent="deleteArticleList()"
                    >
                        선택 삭제
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import DatePicker from "tui-date-picker";
import "tui-date-picker/dist/tui-date-picker.min.css";
export default {
    name: "ArticleList",
    data() {
        return {
            siteNo: "",
            articleList: [],
            boardList: [],
            pagination: {},
            searchForm: {
                contentsNo: "",
                articleTitle: "",
                startDate: "",
                endDate: "",
                countPerPage: 20,
                pageNo: 1,
            },
            displayPageCount: 10,
            isPrev: "",
            isNext: "",
            startPageNo: "",
            endPageNo: "",
            realEndPageNo: "",
            pageNoList: [],
            deleteArticleNoList: [],
            allChecked: false,
        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.selectBoardList();
    },
    watch: {
        deleteArticleNoList() {
            if (this.articleList.length != this.deleteArticleNoList.length) {
                this.allChecked = false;
            } else {
                this.allChecked = true;
            }
        },
    },
    mounted() {
        // 데이트피커
        var today = new Date();
        const rangePicker = new DatePicker.createRangePicker({
            startpicker: {
                date: today,
                input: "#startDate",
                container: "#startDatePicker",
            },
            endpicker: {
                date: today,
                input: "#endDate",
                container: "#endDatePicker",
            },
            language: "ko",
        });
        rangePicker.on("change:start", () => {
            this.searchForm.startDate =
                document.getElementById("startDate").value;
        });
        rangePicker.on("change:end", () => {
            this.searchForm.endDate = document.getElementById("endDate").value;
        });
    },
    methods: {
        selectBoardList() {
            this.logger.debug(this, "selectBoardList()");
            this.searchForm.siteNo = this.siteNo;
            axios
                .post(
                    this.config.contextRoot + "/article/selectBoardList.do",
                    this.searchForm
                )
                .then((response) => {
                    this.boardList = response.data.boardList;
                    if (this.$route.query.contentsNo) {
                        this.searchForm.contentsNo =
                            this.$route.query.contentsNo;
                    } else if (this.boardList.length > 0) {
                        this.searchForm.contentsNo =
                            this.boardList[0].contentsNo;
                    }
                    this.selectArticleList();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        selectArticleList() {
            this.logger.debug(this, "selectArticleList()");
            this.searchForm.siteNo = this.siteNo;
            axios
                .post(
                    this.config.contextRoot + "/article/selectArticleList.do",
                    this.searchForm
                )
                .then((response) => {
                    this.articleList = response.data.articleList;
                    this.pagination = response.data.pagination;
                    this.calcPagination();
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },

        doSearchPage(pageNo) {
            this.logger.debug(this, "doSearchPage()");
            if (typeof pageNo === "undefined") {
                pageNo = 1;
            }
            this.deleteArticleNoList = [];
            this.searchForm.pageNo = pageNo;
            this.selectArticleList();
        },
        calcPagination() {
            this.logger.debug(this, "calcPagination()");
            this.endPageNo =
                Math.ceil(
                    this.pagination.current_page / this.displayPageCount
                ) * this.displayPageCount;
            this.startPageNo = this.endPageNo - this.displayPageCount + 1;

            this.realEndPageNo = Math.ceil(
                this.pagination.total / this.pagination.per_page
            );

            if (this.startPageNo < 0) {
                this.startPageNo = 1;
            }
            if (this.endPageNo > this.realEndPageNo) {
                this.endPageNo = this.realEndPageNo;
            }

            this.isPrev = this.startPageNo == 1 ? false : true;
            this.isNext =
                this.endPageNo * this.pagination.per_page >=
                this.pagination.total
                    ? false
                    : true;

            this.pageNoList = [];
            for (let i = this.startPageNo; i <= this.endPageNo; i++) {
                this.pageNoList.push(i);
            }
        },
        checkAll() {
            this.deleteArticleNoList = [];

            if (!this.allChecked) {
                for (let article of this.articleList) {
                    this.deleteArticleNoList.push(article.articleNo);
                }
            }

            this.allChecked = !this.allChecked;
        },
        deleteArticleList() {
            if (this.deleteArticleNoList.length == 0) {
                alert("삭제항목이 선택되지 않았습니다.");
                return;
            }

            if (!confirm("선택한 항목을 삭제 하시겠습니까?")) {
                return;
            }

            const formData = { deleteArticleNoList: this.deleteArticleNoList };
            axios
                .post(
                    this.config.contextRoot + "/article/deleteArticleList.do",
                    formData
                )
                .then((response) => {
                    if (response.status == 200) {
                        // 마지막 페이지에서 전체 선택 삭제할 경우
                        if (
                            this.startPageNo != this.realEndPageNo &&
                            this.searchForm.pageNo == this.realEndPageNo &&
                            this.deleteArticleNoList.length ==
                                this.articleList.length
                        ) {
                            this.$set(
                                this.searchForm,
                                "pageNo",
                                Number(this.searchForm.pageNo) - 1 + ""
                            );
                        }

                        alert("삭제 되었습니다.");
                        this.deleteArticleNoList = [];
                        this.doSearchPage(this.searchForm.pageNo);
                    }
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
    },
};
</script>

<style>
</style>