<template>
<div class="gnbLayout">
    <h1 class="logo">
        <img src="@/assets/images/layout/logo.png" alt="OSOLIT">
        <span>컨텐츠관리시스템(CMS)</span>
    </h1>
    <GnbSite/>
    <div class="navigation">
        <ul>
            <!-- <li>
                <router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/dashboard'}">
                    <i class="icon">
                        <img src="@/assets/images/layout/menu_1.png" alt="">
                    </i>
                    <span>대시보드</span>
                </router-link>
            </li> -->
            <li>
                <router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/siteInfo'}">
                    <i class="icon">
                        <img src="@/assets/images/layout/menu_2.png" alt="">
                    </i>
                    <span>사이트정보</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/member'}">
                    <i class="icon">
                        <img src="@/assets/images/layout/menu_3.png" alt="">
                    </i>
                    <span>일반회원관리</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/companyApply'}">
                    <i class="icon">
                        <img src="@/assets/images/layout/menu_8.png" alt="">
                    </i>
                    <span>기업회원관리</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/menu'}">
                    <i class="icon">
                        <img src="@/assets/images/layout/menu_4.png" alt="">
                    </i>
                    <span>메뉴관리</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/layout'}">
                    <i class="icon">
                        <img src="@/assets/images/layout/menu_5.png" alt="">
                    </i>
                    <span>레이아웃</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/board'}">
                    <i class="icon">
                        <img src="@/assets/images/layout/menu_6.png" alt="">
                    </i>
                    <span>게시판</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/skin'}" class="childMenu">
                    <i class="icon">
                        <img src="@/assets/images/layout/menu_10.png" alt="">
                    </i>
                    <span>스킨관리</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/article'}" class="childMenu">
                    <i class="icon">
                        <img src="@/assets/images/layout/menu_11.png" alt="">
                    </i>
                    <span>게시글관리</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/contents'}">
                    <i class="icon">
                        <img src="@/assets/images/layout/menu_7.png" alt="">
                    </i>
                    <span>컨텐츠관리</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/visitorStats'}">
                    <i class="icon">
                        <img src="@/assets/images/layout/menu_8.png" alt="">
                    </i>
                    <span>방문자통계</span>
                </router-link>
            </li>
            <!--
            <li>
                <router-link :to="{ path: config.contextRoot + '/site/' + $route.params.siteNo + '/articletats'}">
                    <i class="icon">
                        <img src="@/assets/images/layout/menu_9.png" alt="">
                    </i>
                    <span>게시글통계</span>
                </router-link>
            </li> -->
        </ul>
    </div>
</div>
</template>


<script>
import GnbSite from '@/components/gnb/GnbSite';
export default {
    name: 'Gnb',
    components: {GnbSite}
}
</script>

<style>

</style>