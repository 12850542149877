<template>
    <div class="popupWrap" id="roleListPopup">
        <div class="popupBackground"></div>
        <div class="popup contentPopup">
            <div class="popupHead">
                <h2 class="popupTitle">권한관리</h2>
                <button class="popupClose">
                    <img
                        src="@/assets/images/component/popup-close.png"
                        alt="팝업 닫기"
                        @click="closePopup()"
                    />
                </button>
            </div>

            <!-- 팝업 페이지 1 -->
            <div class="popupPage popupPage-1">
                <div class="popupBody">
                    <div class="contentTitleWrap">
                        <h3 class="contentTitle">권한관리</h3>
                        <div class="titleBtnWrap">
                            <button
                                type="button"
                                class="titleRadiusBtn bgType1"
                                @click="addRole()"
                            >
                                신규
                            </button>
                            <button
                                type="button"
                                class="titleRadiusBtn bgType2"
                                @click="doDelete()"
                            >
                                선택삭제
                            </button>
                        </div>
                    </div>
                    <table class="listTable marginBottom-35">
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        class="checkbox"
                                        v-model="allChecked"
                                        @click="checkAll"
                                    />
                                </th>
                                <th>번호</th>
                                <th>권한명</th>
                                <th>권한설명</th>
                                <th>권한생성일자</th>
                                <th>사용여부</th>
                            </tr>
                        </thead>
                        <tbody
                            v-for="(role, index) in roleList"
                            :key="index"
                            style="
                                background: #fff !important;
                                cursor: default !important;
                            "
                        >
                            <tr>
                                <td>
                                    <label>
                                        <input
                                            type="checkbox"
                                            class="checkbox"
                                            :disabled="
                                                role.adminRoleNo ||
                                                role.memberBaseRoleNo ||
                                                role.roleName ==
                                                    'ROLE_ANONYMOUS'
                                            "
                                            v-model="role.deleteCheck"
                                            true-value="Y"
                                            false-value="N"
                                            @click.stop=""
                                        />
                                    </label>
                                </td>

                                <td>{{ index + 1 }}</td>

                                <td v-if="role.roleNo">{{ role.roleName }}</td>
                                <td v-else>
                                    <input
                                        type="text"
                                        class="inputBox"
                                        v-model="role.roleName"
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        class="inputBox"
                                        v-model="role.roleDiscription"
                                    />
                                </td>

                                <td>
                                    {{ role.roleAddDt }}
                                </td>
                                <td>
                                    <label>
                                        <input
                                            type="checkbox"
                                            class="checkbox"
                                            v-model="role.useYn"
                                            :disabled="
                                                role.adminRoleNo ||
                                                role.memberBaseRoleNo ||
                                                role.roleName ==
                                                    'ROLE_ANONYMOUS'
                                            "
                                            true-value="Y"
                                            false-value="N"
                                            @click.stop=""
                                        />
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="textCenter">
                        <button class="squareBtn bgNavy" @click="saveRole()">
                            저장
                        </button>
                        <button class="squareBtn bgGray" @click="closePopup()">
                            닫기
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";
export default {
    name: "RolePopup",
    data() {
        return {
            roleList: [],
            allChecked: false,
        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.selectRoleList();
    },
    methods: {
        selectRoleList() {
            this.logger.debug(this, "selectRoleList()");
            axios
                .post(this.config.contextRoot + "/role/selectAllRoleList.do", {
                    siteNo: this.siteNo,
                })
                .then((response) => {
                    this.roleList = response.data.roleList;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        addRole() {
            this.roleList.push({
                roleDiscription: "",
                roleName: "",
                siteNo: this.siteNo,
            });
        },
        saveRole() {
            if (!confirm("저장 하시겠습니까?")) {
                return;
            }

            axios
                .post(this.config.contextRoot + "/role/saveRole.do", {
                    siteNo: this.siteNo,
                    roleList: this.roleList,
                })
                .then((response) => {
                    console.log(response);
                    if (response.status == 200) {
                        this.$router.go(this.$router.currentRoute);
                        alert("저장 되었습니다.");
                    }
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        doDelete() {
            let hasDeleteRole = false;

            const roleList = this.roleList.filter((role) => {
                if ( hasDeleteRole != true && role.deleteCheck == "Y" ) {
                    hasDeleteRole = true;
                }
                
                return role.deleteCheck != "Y";
            });

            if (!hasDeleteRole) {
                alert("삭제할 권한을 선택해주세요.");
                return;
            }

            if (!confirm("삭제 하시겠습니까?")) {
                return;
            }

            this.roleList = roleList;

            alert("저장을 클릭하면 삭제가 완료됩니다.");
        },
        checkAll() {
            this.deleteArticleNoList = [];

            if (!this.allChecked) {
                for (let role of this.roleList) {
                    if (this.diabledList.indexOf(role.roleName) == -1) {
                        role.deleteCheck = "Y";
                    }
                }
            } else {
                for (let role of this.roleList) {
                    role.deleteCheck = "N";
                }
            }

            this.allChecked = !this.allChecked;
        },
        closePopup() {
            EventBus.$emit("popupOpenStatus", false);
        },
    },
};
</script>

<style>
</style>