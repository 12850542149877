<template>
	<div>
		<input
			v-if="!downloadOnly"
			type="file"
			name="myfile"
			ref="myfile"
			hidden
			v-validate="'required'"
			enctype="multipart/form-data"
			multiple="true"
			@change="upload($event.target.name, $event.target.files)"
			@drop="upload($event.target.name, $event.target.files)"
		/>
		<input
			v-if="!downloadOnly"
			type="text"
			class="file"
			:value="'(' + fileMaxSize + 'MB, 확장자: ' + fileExtension + ')'"
			style="background-color: #fff; color: #878b8f !important; width:91% !important;"
			readonly
		/>
		<button v-if="!downloadOnly" type="button" class="upload" :style="(height == '' ? 'height: 45px;' : 'height: ' + height + 'px;')" @click.prevent="openUploader()">
			Upload
		</button>
		<div>
			<table>
				<tr v-for="(item, index) in value" :key="item.serverFileSaveName">
					<td
						style="
							padding: 3px;
							font-weight: bold;
							box-sizing: border-box;
							margin: 0;
							line-height: 1.6em;
							border: 0px none;
						"
					>
						<img
							src="data:image/gif;base64,R0lGODlhBwAMAIABAD9tmf///yH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjAgNjEuMTM0Nzc3LCAyMDEwLzAyLzEyLTE3OjMyOjAwICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjI3NzJhYmIwLTI3ZjQtZjg0YS04ZmRiLTFjYWE0Njk0MmZlOSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo3NjVEQzEyNjU4MTExMUU0OTI4MUY5RTJDMkUxRTJCNCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo3NjVEQzEyNTU4MTExMUU0OTI4MUY5RTJDMkUxRTJCNCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IFdpbmRvd3MiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBNTk4Q0Q0NUVBNTVFNDExOTNCNUY5MERBRDRCMzk2NSIgc3RSZWY6ZG9jdW1lbnRJRD0iYWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOjcyNWMxN2NiLTU0MmUtMTFlNC1hODdjLWY4YTQ5ZmM2YjA5MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgH//v38+/r5+Pf29fTz8vHw7+7t7Ovq6ejn5uXk4+Lh4N/e3dzb2tnY19bV1NPS0dDPzs3My8rJyMfGxcTDwsHAv769vLu6ubi3trW0s7KxsK+urayrqqmop6alpKOioaCfnp2cm5qZmJeWlZSTkpGQj46NjIuKiYiHhoWEg4KBgH9+fXx7enl4d3Z1dHNycXBvbm1sa2ppaGdmZWRjYmFgX15dXFtaWVhXVlVUU1JRUE9OTUxLSklIR0ZFRENCQUA/Pj08Ozo5ODc2NTQzMjEwLy4tLCsqKSgnJiUkIyIhIB8eHRwbGhkYFxYVFBMSERAPDg0MCwoJCAcGBQQDAgEAACH5BAEAAAEALAAAAAAHAAwAAAITjIEWoLx9XmKUSlvlctxBDyZBAQA7"
							style="padding-right: 5px"
						/>
						<a
							href="javascript:;"
							@click="download(item.fileGroupNo, item.fileName, item.serverFileSaveName)"
							>{{ item.fileName }}</a
						>
					</td>
					<td style="padding: 3px; box-sizing: border-box; margin: 0; line-height: 1.6em; border: 0px none">
						{{ item.fileSizeName }}
					</td>
					<td v-if="downloadOnly && checkedImageFile(item.fileExtensionName)">
						<button @click="popupImagePreview(item)" type="button">미리보기</button>
					</td>
					<td style="padding: 3px" v-if="!downloadOnly"><button @click="deleteFile(index)">삭제</button></td>
					<input type="hidden" name="fileGroupNo" :value="item.fileGroupNo" />
					<input type="hidden" name="fileNo" :value="item.fileNo" />
					<input type="hidden" name="fileName" :value="item.fileName" />
					<input type="hidden" name="fileExtensionName" :value="item.fileExtensionName" />
					<input type="hidden" name="fileType" :value="item.fileType" />
					<input type="hidden" name="fileSize" :value="item.fileSize" />
					<input type="hidden" name="fileSizeName" :value="item.fileSizeName" />
					<input type="hidden" name="serverFileSavePath" :value="item.serverFileSavePath" />
					<input type="hidden" name="serverFileSaveName" :value="item.serverFileSaveName" />
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import EventBus from "@/script/EventBus.js";

export default {
	name: "CommonFile",
	created() {
		// 자식 Event 감지
		EventBus.$on("closeImagePopUp", () => {
			this.logger.debug(this, "[EventBus] closePopUp");
			this.isPopup = false;
		});
	},
	mounted() {},
	data() {
		return {
			isPopup: false,
			popup: {}
		};
	},
	props: {
		value: {
			type: Array,
			default: function() {
				return [];
			},
		},
		downloadOnly: {
			type: Boolean,
			default: false,
		},
		fileMaxSize: {
			type: String,
		},
		fileExtension: {
			type: String,
		},
		height: {
			type: String
		}
	},
	methods: {
		upload(name, files) {
			const formData = new FormData();
			for (let idx = 0; idx < files.length; idx++) {	

				formData.append(name, files[idx], files[idx].name);
			}
			axios.post("/common/file/upload.do", formData).then((response) => {
				console.log(response);
				for (let idx = 0; idx < response.data.fileList.length; idx++) {
					this.value.push(response.data.fileList[idx]);
				}
				this.$emit("input", this.value);
			});
		},
		download(fileNo, fileName, serverFileSaveName) {
			this.logger.debug(this, "download()");

			const formData = new FormData();
			formData.append("fileNo", fileNo);
			formData.append("fileName", fileName);
			formData.append("serverFileSaveName", serverFileSaveName);

			axios
				.post("/common/file/download.do", formData, {responseType: "blob"})
				.then((response) => {
					console.log(response);
					const url = window.URL.createObjectURL(
						new Blob([response.data], {type: response.headers["content-type"]})
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", fileName);
					document.body.appendChild(link);
					link.click();
				});
		},
		deleteFile(index) {
			this.value.splice(index, 1);
		},
		openUploader() {
			this.logger.debug(this, "openUploader()");
			this.$refs.myfile.click();
		},
		checkedImageFile(fileExtensionName) {
			const extensions = "JPG,JPEG,PNG,BMP,GIF,TIFF,TIF";
			if (extensions.indexOf(fileExtensionName.toUpperCase()) > -1) {
				return true;
			}
			return false;
		},
	},
};
</script>
