<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">레이아웃</h2>
        </div>
        <div class="contentBody">
            <div class="contentTitleWrap">
                <h3 class="contentTitle">레이아웃 수정</h3>
                <div class="titleBtnWrap">
                    <button
                        class="radiusInputBtn"
                        type="button"
                        @click="openPopup"
                    >
                        레이아웃 이력
                    </button>
                </div>
            </div>

            <!-- 입력 폼 -->
            <div class="infoWrap">
                <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
                <table class="infoTable marginBottom-35">
                    <tbody>
                        <tr>
                            <th class="requiredMark">레이아웃명</th>
                            <td colspan="3">
                                <input
                                    type="text"
                                    class="inputBox"
                                    v-model="layoutInfo.layoutName"
                                    v-validate="'required'"
                                    data-vv-name="layoutName"
                                    data-vv-as="레이아웃명"
                                />
                                <p
                                    class="validation-text"
                                    v-if="errors.has('layoutName')"
                                >
                                    * {{ errors.first("layoutName") }}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th class="requiredMark">사용여부</th>
                            <td>
                                <div class="radioBoxWrap">
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-6"
                                            v-model="layoutInfo.useYn"
                                            value="Y"
                                        />
                                        <span>사용</span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            class="radioBox"
                                            name="testRadio-6"
                                            v-model="layoutInfo.useYn"
                                            value="N"
                                        />
                                        <span>미사용</span>
                                    </label>
                                </div>
                            </td>
                            <th>생성일자</th>
                            <td>
                                {{ layoutInfo.layoutAddDt }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="sectionLabel">
                    <strong class="sectionLabelTitle">헤더</strong>
                </div>
                <p class="validation-text" v-if="errors.has('layoutHeader')">
                    * {{ errors.first("layoutHeader") }}
                </p>
                <textarea
                    class="textArea borderDarkGray marginBottom-15"
                    v-validate="'required'"
                    data-vv-name="layoutHeader"
                    data-vv-as="헤더"
                    v-model="layoutInfo.layoutHeader"
                    placeholder="내용을 입력하세요."
                ></textarea>

                <div class="sectionLabel">
                    <strong class="sectionLabelTitle">푸터</strong>
                </div>
                <p class="validation-text" v-if="errors.has('layoutFooter')">
                    * {{ errors.first("layoutFooter") }}
                </p>
                <textarea
                    class="textArea borderDarkGray"
                    v-validate="'required'"
                    data-vv-name="layoutFooter"
                    data-vv-as="푸터"
                    v-model="layoutInfo.layoutFooter"
                    placeholder="내용을 입력하세요."
                ></textarea>
            </div>

            <!-- 하단 버튼 바 -->
            <div class="contentButtonBar">
                <div class="buttonWrap floatLeft">
                    <router-link
                        :to="{
                            path:
                                config.contextRoot +
                                '/site/' +
                                $route.params.siteNo +
                                '/layout',
                        }"
                        class="radiusSmallBtn bgGray"
                    >
                        목록
                    </router-link>
                </div>
                <div class="buttonWrap floatRight">
                    <button
                        class="radiusSmallBtn bgNavy"
                        @click="updateLayout()"
                    >
                        저장
                    </button>
                    <button
                        class="radiusSmallBtn bgDark"
                        @click="deleteLayout()"
                    >
                        삭제
                    </button>
                </div>
            </div>

            <!-- 이력 팝업 -->
            <div class="popupWrap" id="roleListPopup" v-show="isShowPopup">
                <div class="popupBackground"></div>
                <div class="popup contentPopup">
                    <div class="popupHead">
                        <h2 class="popupTitle">레이아웃 이력</h2>
                        <button class="popupClose">
                            <img
                                src="@/assets/images/component/popup-close.png"
                                alt="팝업 닫기"
                                @click="closePopup()"
                            />
                        </button>
                    </div>

                    <!-- 팝업 페이지 1 -->
                    <div
                        class="popupPage popupPage-1"
                        v-show="popupPage[0] == 1"
                    >
                        <div class="popupBody">
                            <div class="contentTitleWrap">
                                <h3 class="contentTitle">레이아웃 이력 목록</h3>
                            </div>

                            <table class="listTable">
                                <thead>
                                    <tr>
                                        <th>번호</th>
                                        <th>레이아웃명</th>
                                        <th>이력일시</th>
                                    </tr>
                                </thead>
                                <tbody
                                    v-for="history in historyList"
                                    :key="history.historyNo"
                                    @click="
                                        showHistoryDetail(history.historyNo)
                                    "
                                >
                                    <tr>
                                        <td>{{ history.rowNumber }}</td>
                                        <td>
                                            <a href="#" @click.prevent="">{{
                                                history.layoutName
                                            }}</a>
                                        </td>
                                        <td>{{ history.historyDate }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div
                                class="pagination marginTop-50"
                                v-if="pageNoList && pageNoList.length > 0"
                            >
                                <ul>
                                    <li>
                                        <a
                                            href="#"
                                            @click.prevent="doSearchPage(1)"
                                            title="첫 페이지"
                                        >
                                            <img
                                                src="@/assets/images/component/pagination-first.png"
                                                alt="첫 페이지"
                                            />
                                        </a>
                                    </li>
                                    <li v-show="isPrev">
                                        <a
                                            href="#"
                                            @click.prevent="
                                                doSearchPage(startPageNo - 1)
                                            "
                                            :title="
                                                '이전' +
                                                displayPageCount +
                                                '페이지'
                                            "
                                        >
                                            <img
                                                src="@/assets/images/component/pagination-prev.png"
                                                :alt="
                                                    '이전' +
                                                    displayPageCount +
                                                    '페이지'
                                                "
                                            />
                                        </a>
                                    </li>
                                    <li
                                        v-for="pageNo in pageNoList"
                                        :key="pageNo"
                                    >
                                        <a
                                            href="#"
                                            :class="
                                                pagination.current_page ==
                                                pageNo
                                                    ? 'active'
                                                    : ''
                                            "
                                            @click.prevent="
                                                doSearchPage(pageNo)
                                            "
                                        >
                                            {{ pageNo }}
                                        </a>
                                    </li>
                                    <li v-show="isNext">
                                        <a
                                            href="#"
                                            @click.prevent="
                                                doSearchPage(endPageNo + 1)
                                            "
                                            :title="
                                                '다음' +
                                                displayPageCount +
                                                '페이지'
                                            "
                                        >
                                            <img
                                                src="@/assets/images/component/pagination-next.png"
                                                :alt="
                                                    '다음' +
                                                    displayPageCount +
                                                    '페이지'
                                                "
                                            />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            @click.prevent="
                                                doSearchPage(realEndPageNo)
                                            "
                                            title="마지막 페이지"
                                        >
                                            <img
                                                src="@/assets/images/component/pagination-last.png"
                                                alt="마지막 페이지"
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- 팝업 페이지 2 -->
                    <div
                        class="popupPage popupPage-2"
                        v-show="popupPage[1] == 1"
                    >
                        <div class="popupBody">
                            <div class="contentTitleWrap">
                                <h3 class="contentTitle">
                                    <small style="font-weight: 300">
                                        일시:
                                        {{ historyDetail.historyDate }}
                                    </small>
                                </h3>

                                <div class="titleBtnWrap">
                                    <button
                                        type="button"
                                        class="titleRadiusBtn bgType1"
                                        @click="showHistoryList"
                                    >
                                        목록
                                    </button>
                                </div>
                            </div>
                            <div style="height: 500px; overflow: auto">
                                <div class="sectionLabel">
                                    <strong class="sectionLabelTitle">
                                        헤더
                                    </strong>
                                </div>
                                <textarea
                                    readonly
                                    class="
                                        textArea
                                        borderDarkGray
                                        marginBottom-15
                                    "
                                    :value="historyDetail.layoutHeader"
                                >
                                </textarea>
                                <div class="sectionLabel">
                                    <strong class="sectionLabelTitle">
                                        푸터
                                    </strong>
                                </div>
                                <textarea
                                    readonly
                                    class="
                                        textArea
                                        borderDarkGray
                                        marginBottom-15
                                    "
                                    :value="historyDetail.layoutFooter"
                                >
                                </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "LayoutEdit",
    data() {
        return {
            siteNo: "",
            layoutInfo: {
                layoutName: "",
                useYn: "Y",
                layoutHeader: "",
                layoutFooter: "",
            },
            // 이력 팝업 관련..
            isShowPopup: false,
            popupPage: [1, 0],
            historyDetail: {},
            historyList: [],
            pagination: {},
            displayPageCount: 10,
            pageNoList: [],
        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.selectLayout();
    },
    methods: {
        // 이력 팝업 관련..
        doSearchPage(pageNo) {
            this.logger.debug(this, "doSearchPage()");
            if (typeof pageNo === "undefined") {
                pageNo = 1;
            }
            this.selectHistoryList(pageNo);
        },
        calcPagination() {
            this.logger.debug(this, "calcPagination()");
            this.endPageNo =
                Math.ceil(
                    this.pagination.current_page / this.displayPageCount
                ) * this.displayPageCount;
            this.startPageNo = this.endPageNo - this.displayPageCount + 1;

            this.realEndPageNo = Math.ceil(
                this.pagination.total / this.pagination.per_page
            );

            if (this.startPageNo < 0) {
                this.startPageNo = 1;
            }
            if (this.endPageNo > this.realEndPageNo) {
                this.endPageNo = this.realEndPageNo;
            }

            this.isPrev = this.startPageNo == 1 ? false : true;
            this.isNext =
                this.endPageNo * this.pagination.per_page >=
                this.pagination.total
                    ? false
                    : true;

            this.pageNoList = [];
            for (let i = this.startPageNo; i <= this.endPageNo; i++) {
                this.pageNoList.push(i);
            }
        },
        showHistoryList() {
            this.popupPage = [1, 0];
        },
        showHistoryDetail(historyNo) {
            this.selectHistory(historyNo).then(() => {
                this.popupPage = [0, 1];
            });
        },
        closePopup() {
            this.isShowPopup = false;
            this.popupPage = [1, 0];
        },
        openPopup() {
            this.selectHistoryList().then(() => {
                this.isShowPopup = true;
            });
        },
        selectHistory(historyNo) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        this.config.contextRoot + "/layout/selectHistory.do",
                        {
                            historyNo: historyNo,
                        }
                    )
                    .then((response) => {
                        this.historyDetail = response.data.history;
                        resolve();
                    })
                    .catch((error) => {
                        this.exceptionHandler(this, error);
                        reject();
                    });
            });
        },
        selectHistoryList(pageNo) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        this.config.contextRoot +
                            "/layout/selectHistoryList.do",
                        {
                            layoutNo: this.layoutNo,
                            pageNo: pageNo ? pageNo : 1,
                        }
                    )
                    .then((response) => {
                        this.historyList = response.data.historyList;
                        this.pagination = response.data.pagination;
                        this.calcPagination();
                        resolve();
                    })
                    .catch((error) => {
                        this.exceptionHandler(this, error);
                        reject();
                    });
            });
        },
        // 레이아웃 수정 관련..
        selectLayout() {
            this.logger.debug(this, "selectLayout()");
            this.layoutNo = this.$route.params.layoutNo;
            const formData = {
                layoutNo: this.layoutNo,
            };

            axios
                .post(
                    this.config.contextRoot + "/layout/selectLayout.do",
                    formData
                )
                .then((response) => {
                    this.layoutInfo = response.data.layoutInfo;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        updateLayout() {
            this.logger.debug(this, "updateLayout()");

            if (!confirm("수정 하시겠습니까?")) {
                return;
            }

            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }

                    this.layoutInfo.siteNo = this.siteNo;

                    axios
                        .post(
                            this.config.contextRoot + "/layout/updateLayout.do",
                            this.layoutInfo
                        )
                        .then((response) => {
                            if (response.data.layoutNo) {
                                alert("수정 되었습니다.");
                                this.$router.replace(
                                    this.config.contextRoot +
                                        "/site/" +
                                        this.siteNo +
                                        "/layout/"
                                );
                            }
                        })
                        .catch((error) => {
                            this.exceptionHandler(this, error);
                        });
                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.errors.all());
                    return false;
                });
        },
        deleteLayout() {
            if (confirm("삭제 하시겠습니까?")) {
                axios
                    .post(
                        this.config.contextRoot + "/layout/deleteLayout.do",
                        this.layoutInfo
                    )
                    .then((response) => {
                        if (response) {
                            alert("삭제 되었습니다.");
                            this.$router.replace(
                                this.config.contextRoot +
                                    "/site/" +
                                    this.siteNo +
                                    "/layout/"
                            );
                        }
                    })
                    .catch((error) => {
                        if (
                            error.response.data.message ==
                            "SQLIntegrityConstraintViolationException"
                        ) {
                            alert(
                                "해당 레이아웃은 메뉴관리에서 사용 중이므로 삭제할 수 없습니다."
                            );
                            return;
                        }
                        this.exceptionHandler(this, error);
                    });
            }
        },
    },
};
</script>

<style>
</style>